import { Stack, Typography } from '@mui/material';
import React from 'react';
import { BackModal } from './back-modal';

type DialogTitleOrderProps = {
  back?: () => void;
};

export const DialogTitleOrder = (props: DialogTitleOrderProps) => (
  <Stack
    marginTop={10}
  >
    {props.back ? <BackModal onClick={props.back}/> : null}
    <Typography
      fontWeight={'normal'}
      fontSize={23}
      alignSelf={'center'}
    >
      Оформление заявки
    </Typography>
  </Stack>
);
