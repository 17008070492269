import React from 'react';

export const FreezerFrigeImage = () => (
  <svg
    width={'50'}
    height={'51'}
    viewBox={'0 0 50 51'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <g clipPath={'url(#clip0_1165_2838)'}>
      <path
        /* eslint-disable-next-line max-len */
        d={'M39.2352 0.627319H10.7687C8.84766 0.627319 7.28906 2.29763 7.28906 4.35779V17.2945H42.7133V4.35779C42.7141 2.29763 41.1563 0.627319 39.2352 0.627319ZM15.5766 11.4711C15.5766 12.0734 15.0367 12.5609 14.3695 12.5609C13.7031 12.5609 13.1633 12.0734 13.1633 11.4711V6.58123C13.1633 5.97888 13.7031 5.49138 14.3695 5.49138C15.0367 5.49138 15.5766 5.97888 15.5766 6.58123V11.4711Z'}
        fill={'url(#paint0_linear_1165_2838)'}
      />
      <path
        /* eslint-disable-next-line max-len */
        d={'M7.28906 47.1617C7.28906 48.9359 8.84766 50.3734 10.7687 50.3734H39.2359C41.1555 50.3734 42.7133 48.9359 42.7133 47.1617V19.7133H7.28906V47.1617ZM13.1625 27.1101C13.1625 26.3133 13.7023 25.6672 14.3688 25.6672C15.0359 25.6672 15.5758 26.3133 15.5758 27.1101V33.5843C15.5758 34.3812 15.0359 35.0265 14.3688 35.0265C13.7023 35.0265 13.1625 34.3812 13.1625 33.5843V27.1101Z'}
        fill={'url(#paint1_linear_1165_2838)'}
      />
    </g>
    <defs>
      <linearGradient
        id={'paint0_linear_1165_2838'}
        x1={'-3.76329'}
        y1={'-0.132146'}
        x2={'0.0519844'}
        y2={'25.1343'}
        gradientUnits={'userSpaceOnUse'}
      >
        <stop stopColor={'#7851CF'}/>
        <stop offset={'0.166667'} stopColor={'#7C71E8'}/>
        <stop offset={'1'} stopColor={'#808EFF'}/>
      </linearGradient>
      <linearGradient
        id={'paint1_linear_1165_2838'}
        x1={'-3.76329'}
        y1={'18.3162'}
        x2={'8.4959'}
        y2={'62.4497'}
        gradientUnits={'userSpaceOnUse'}
      >
        <stop stopColor={'#7851CF'}/>
        <stop offset={'0.166667'} stopColor={'#7C71E8'}/>
        <stop offset={'1'} stopColor={'#808EFF'}/>
      </linearGradient>
      <clipPath id={'clip0_1165_2838'}>
        <rect width={'50'} height={'50'} fill={'white'} transform={'translate(0 0.5)'}/>
      </clipPath>
    </defs>
  </svg>
);
