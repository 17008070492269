import styled from 'styled-components';
import { mediaBreakpoints } from '~/ui';

export const TextFieldWrapper = styled('div')`
  
  width: 100%;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    margin-bottom: 8px;
  }

  & > * {
    width: 100%;

    .MuiInputBase-root:not(.MuiInputBase-multiline) {
      background-color: white;
      border-radius: 20px;
      height: 64px;

      input {
        font-size: 16px;
        padding: 16.5px 14px 16.5px 24px;
      }
    }

    .MuiInputBase-multiline {
      padding: 16.5px 14px 16.5px 24px;
      border-radius: 20px;

      textarea {
        font-size: 16px;
        line-height: 1;
        height: auto;

      }
    }

    .MuiFormHelperText-root {
      font-size: 14px;
    }
  }
`;
