import { Stack, Container } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { mediaBreakpoints } from '~/ui';
import caseIcon from '~/ui/images/footer-case.svg';
import mapDotIcon from '~/ui/images/footer-map-dot.svg';
import phoneIcon from '~/ui/images/footer-phone.svg';
import fbg from '~/ui/images/footerbg.svg';
import logoWhite from '~/ui/images/logo-white.svg';
import { LinksToMobileApps } from './links-to-mobile-apps';

const FooterBox = styled.div`
  background: url(${fbg}) no-repeat center center, linear-gradient(269.35deg, #808BFD 37.18%, #7958D5 72.05%);
  color: white;
  padding: 30em 0;
  font-size: ${1 / 14.4}vw;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    font-size: ${1 / 3.75}vw;
  },
@media screen and (min-width: ${mediaBreakpoints.desktopToLarges}px) {
  font-size: 1.38889px;
},
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    flex-direction: column;
  }
`;

const FlexItem = styled.div`
  flex: 1;
`;
const Text = styled.div`
  font-size: 17em;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 36em auto auto;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    grid-template-columns: 36em auto;
    row-gap: 20em;
    .last {
      grid-column-start: 2;
    }
  }

  & > img {
    width: 28em;
    flex-shrink: 0;
  }
`;

const Divider = styled.div`
  background: rgba(255, 255, 255, 0.5);
  width: 2em;
  margin: 0 100em;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    width: auto;
    height: 2em;
    margin: 30em 0;
  }
`;
const Phone = styled.a`
  text-decoration: none;
  color: inherit;
`;
const PhoneCaption = styled.div`
  font-weight: 400;
  font-size: 12em;
  line-height: 140%;
  color: rgba(255, 255, 255, 0.5);
`;

export const Footer: React.FC = () => (
  <FooterBox>
    <Container>
      <Flex>
        <FlexItem>
          <Stack spacing={6}>
            <Row>
              <img src={caseIcon}/>
              <Text>
                ООО “Техника вашего дома”
                <br/>
                ИНН 5047257258
              </Text>
            </Row>
            <Row>
              <img src={phoneIcon}/>
              <Text>
                141402 Московская обл., г. Химки,
                <br/>
                ул. Репина, 2/27, этаж 9 офис 903
              </Text>
            </Row>
            <Row>
              <img src={mapDotIcon}/>

              <Phone href={'tel: 7515'}>
                <PhoneCaption>
                  Бесплатно с мобильного
                </PhoneCaption>
                <Text>7515</Text>
              </Phone>
              <Phone href={'tel: 8 (800) 770-71-08'} className={'last'}>
                <PhoneCaption>Бесплатно по России</PhoneCaption>
                <Text>8 (800) 770-71-08</Text>
              </Phone>
            </Row>
          </Stack>
        </FlexItem>
        <Divider/>
        <FlexItem>
          <Stack spacing={5}>
            <Stack spacing={2}>
              <div>
                <img src={logoWhite} alt={'МайТех'} height={30}/>
              </div>
              <Text>
                Скачивайте приложение МайТех в Google Play и App Store
              </Text>
            </Stack>
            <div>
              <LinksToMobileApps/>
            </div>
            <Text>
              &copy; 2022 mytech.global
            </Text>
          </Stack>
        </FlexItem>
      </Flex>
    </Container>
  </FooterBox>
);

