import React from 'react';

export const LaundryMachineImage = () => (
  <svg
    width={'43'}
    height={'59'}
    viewBox={'0 0 43 59'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <path
      d={'M3 4C3 2.89543 3.89543 2 5 2L38 2C39.1046 2 40 2.89543 40 4L40 18L3 18L3 4Z'}
      fill={'url(#paint0_linear_1165_2934)'}
    />
    <path
      /* eslint-disable-next-line max-len */
      d={'M7 56C5.11438 56 4.17157 56 3.58579 55.4142C3 54.8284 3 53.8856 3 52L3 19L40 19L40 52C40 53.8856 40 54.8284 39.4142 55.4142C38.8284 56 37.8856 56 36 56L7 56Z'}
      fill={'url(#paint1_linear_1165_2934)'}
    />
    <rect x={'9'} y={'24'} width={'2'} height={'25'} rx={'0.999999'} transform={'rotate(-90 9 24)'} fill={'#E9E8FC'}/>
    <circle cx={'19.707'} cy={'10.8511'} r={'1.5'} fill={'#F0EEFB'}/>
    <circle cx={'13.707'} cy={'10.8511'} r={'1.5'} fill={'#F0EEFB'}/>
    <circle cx={'7.57812'} cy={'10.8511'} r={'1.5'} fill={'#F0EEFB'}/>
    <defs>
      <linearGradient
        id={'paint0_linear_1165_2934'}
        x1={'-8.544'}
        y1={'1.27094'}
        x2={'-5.1661'}
        y2={'25.6103'}
        gradientUnits={'userSpaceOnUse'}
      >
        <stop stopColor={'#7851CF'}/>
        <stop offset={'0.166667'} stopColor={'#7C71E8'}/>
        <stop offset={'1'} stopColor={'#808EFF'}/>
      </linearGradient>
      <linearGradient
        id={'paint1_linear_1165_2934'}
        x1={'1.31404'}
        y1={'67.544'}
        x2={'53.3256'}
        y2={'50.8516'}
        gradientUnits={'userSpaceOnUse'}
      >
        <stop stopColor={'#7851CF'}/>
        <stop offset={'0.166667'} stopColor={'#7C71E8'}/>
        <stop offset={'1'} stopColor={'#808EFF'}/>
      </linearGradient>
    </defs>
  </svg>
);
