import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(
  {
    viewBox: '0 0 111 110', fill: 'none', xmlns: 'http://www.w3.org/2000/svg',
  },
)`
  width: 111px;
  height: 110px;
  @media (max-width: 768px) {
    width: 65px;
    height: 65px;
  }
`;

export const ProcessingSuccessIcon = () => (
  <Svg>
    <circle cx={'55.4168'} cy={'54.9998'} r={'54.6512'} fill={'#E6DFF9'} fillOpacity={'0.25'}/>
    <ellipse cx={'55.4205'} cy={'55.0001'} rx={'47.6744'} ry={'47.6744'} fill={'#E6DFF9'} fillOpacity={'0.45'}/>
    <rect x={'15.3008'} y={'14.3022'} width={'81.3954'} height={'81.3954'} rx={'40.6977'} fill={'#E6DFF9'}/>
    <path d={'M72.9174 43.3984L71.8417 42.3227C70.6581 41.1379 68.721 41.1379 67.5375 42.3227L49.2499 60.6103L42.7615 54.1233C41.5792 52.9397 39.6409 52.9397 38.4586 54.1245L37.3828 55.199C36.2005 56.3826 36.2005 58.3197 37.3828 59.5032L47.0921 69.22C48.2757 70.4049 50.2128 70.4049 51.3963 69.22L72.9174 47.7027C74.0973 46.5191 74.0973 44.582 72.9174 43.3984Z'} fill={'#6156D5'}/>
  </Svg>
);
