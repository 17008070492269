import { ApiParams } from '~/1st-api';
import { CrudRestClient } from '~/1st-crud';
import {
  mapPayment,
  mapPaymentRaw,
  mapSentPayment,
  Payment,
  PaymentRaw,
  PublicPayment,
  SentPayment,
} from '../schema';

export class PaymentRestClient extends CrudRestClient<Payment, PaymentRaw> {
  protected url = '/api/payment';

  protected mapModel = mapPayment;

  protected mapModelRaw = mapPaymentRaw;

  send(params: ApiParams<SentPayment>): Promise<string> {
    return this.fetchText({
      method: 'post',
      endpoint: '/send',
      body: mapSentPayment(params.data),
    });
  }

  check(params: ApiParams<undefined, { paymentId: string; }>): Promise<PublicPayment> {
    return this.fetchJson({
      method: 'get',
      endpoint: `/check?paymentId=${params.query.paymentId}`,
    });
  }
}
