import { Stack } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { Processing, ProcessingProps } from '~/ui';
import { Layout } from './layout';
import { LogoIcon } from './logo-icon';

interface ProcessingComponentProps extends ProcessingProps {
  errorText?: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 546px;
  width: 100%;
  margin: 84px auto 120px;
`;

const ProcessingWrapper = styled.div`
  margin: 120px auto 0;
  background: white;
  padding: 50px 60px;
  border-radius: 14px;
  @media (max-width: 450px) {
    padding: 30px 0;
    background: inherit;
    margin-top: 40px;
  }
`;

export const ProcessingComponent: React.FC<ProcessingComponentProps> = (props) => (
  <Layout isNeedHeader={false}>
    <Wrapper>
      <a href={'https://mytech.global/'}>
        <Stack sx={{ cursor: 'pointer' }}>
          <LogoIcon/>
        </Stack>
      </a>
      <ProcessingWrapper>
        <Processing
          isSuccess={props.isSuccess}
          errorText={props.errorText}
          title={props.title}
          subtitleText={props.subtitleText}
        />
      </ProcessingWrapper>
    </Wrapper>
  </Layout>
);
