import {
  Stack,
  Typography,
  TextField,
  Checkbox,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import { Formik } from 'formik';
import MaterialUiPhoneNumber from 'material-ui-phone-number';
import React, { useCallback} from 'react';
import { createUseStyles } from 'react-jss';
import * as Yup from 'yup';
import {
  Color,
  IconCheckboxNotChecked,
  IconCheckboxChecked,
  Footer,
} from '~/ui';
import { CustomDatePicker } from '~/ui/components/custom-date-picker';
import { useMainService, useOrderService } from '../di';
import { BackIcon } from './back-icon';
import { CloseIconCircle } from './close-icon-circle';
import { InfoFormIcon } from './info-form-icon';
import { TextFieldWrapper } from './text-field-wrapper';

const months = [
  '',
  'Января',
  'Февраля',
  'Марта',
  'Апреля',
  'Мая',
  'Июня',
  'Июля',
  'Августа',
  'Сентября',
  'Октября',
  'Ноября',
  'Декабря',
];

type OrderModalMobileProps = {
  id: string;
  title?: string;
  price?: string;
  onClose: () => void;
  onBack: () => void;
};

const useStyles = createUseStyles({
  OrderModal: {
    'backgroundColor': Color.Gray1,
    'position': 'relative',
    'padding': '10px 45px 15px 54px',
    '@media(max-width: 1032px)': {
      padding: '80px 15px 3px 10px',
    },
  },
  OrderModal__BackIcon: {
    position: 'absolute',
    top: 20,
    left: 8,
    zIndex: 1,
  },
  OrderModal__Close: {
    position: 'absolute',
    top: 20,
    right: 8,
    cursor: 'pointer',
  },
  OrderModal__Button: {
    'backgroundColor': '#1B2040',
    'color': Color.White,
    'padding': '12px 67px 12px 67px',
    'borderRadius': 12,
    'border': 'none',
    'cursor': 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(9, 14, 43, 1)',
    },
    '&:active': {
      backgroundColor: 'rgba(6, 10, 33, 1)',
    },
  },
  OrderModal__Error: {
    fontWeight: 500,
    fontSize: '14px',
    marginTop: 5,
    height: 15,
    color: 'rgba(233, 120, 116, 1)',
  },
});

export type OrderModalMobileData = {
  name: string;
  phoneNumber: string;
  address: string;
  date: string;
  personalData: boolean;
  oferta: boolean;
};

export const OrderModalMobile = (props: OrderModalMobileProps) => {
  const styles = useStyles();

  const mainService = useMainService();

  // const [selectedRange, setSelectedRange] = useState('');
  // const [isOpenModal, setIsOpenModal] = useState(false);

  // const openDatePopUp = () => {
  //   setIsOpenModal(true);
  // };

  // const openPopUpByField = (event: React.MouseEvent<HTMLElement>) => {
  //   event.preventDefault();
  //   openDatePopUp();
  // };

  const orderService = useOrderService();

  const handleSubmit = useCallback(
    async (data: OrderModalMobileData) => {
      orderService.setOrderData(props.title, props.price);
      console.log(data);
      // navigate('payment');
      mainService.sendOrder({
        ...data,
        date: `${data.date} 11:00—13:00`,
        id: props.id,
      })
        .then((res) => {
          console.log(res);
          window.location.replace(res);
        })
        .catch((err) => console.log(err));
    },
    [],
  );

  const initialValues: OrderModalMobileData = {
    name: '',
    phoneNumber: '',
    address: '',
    date: '',
    personalData: true,
    oferta: true,
  };

  const validationSchema = Yup.object()
    .shape({
      name: Yup.string()
        .required('Заполните поле.'),
      phoneNumber: Yup.string()
        .required('Заполните поле.')
        .min(18, 'Номер телефона заполнен неверно'),
      address: Yup.string()
        .required('Заполните поле.'),
      date: Yup.string()
        .required('Заполните поле.'),
    });

  return (
    <Stack spacing={3.5}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <div
              className={styles.OrderModal}
            >
              {props.onBack && (
              <Stack
                onClick={props.onBack}
                className={styles.OrderModal__BackIcon}
              >
                <BackIcon backgroundColor={Color.White}/>
              </Stack>
              )}
              <Stack
                onClick={props.onClose}
                className={styles.OrderModal__Close}
              >
                <CloseIconCircle/>
              </Stack>
              <Stack spacing={2.5}>
                <Typography
                  fontWeight={'normal'}
                  fontSize={23}
                  alignSelf={'center'}
                >
                  Оформление заявки
                </Typography>
                <Stack spacing={2.5}>
                  <TextFieldWrapper>
                    <TextField
                      error={Boolean(formik.touched.name && formik.errors.name)}
                      id={'name'}
                      placeholder={'Имя'}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      // helperText={formik.touched.name && formik.errors.name}
                      fullWidth
                      sx={{ backgroundColor: Color.Gray1 }}
                    />
                    <Typography className={styles.OrderModal__Error}>
                      {formik.touched.name && formik.errors.name}
                    </Typography>
                  </TextFieldWrapper>
                  <TextFieldWrapper>
                    <MaterialUiPhoneNumber
                      disableDropdown
                      fullWidth
                      defaultCountry={'ru'}
                      onBlur={formik.handleBlur}
                      // helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                      error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                      onChange={formik.handleChange('phoneNumber')}
                      name={'phoneNumber'}
                      value={formik.values.phoneNumber}
                      variant={'filled'}
                      label={'Телефон'}
                      id={'phone'}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          backgroundColor: 'white',
                          border: '1px solid #bcbfd1',
                          borderRadius: 16,
                        },
                      }}
                      InputLabelProps={{
                        style: { color: '#BCBFD1' },
                      }}

                    />
                    <Typography className={styles.OrderModal__Error}>
                      {formik.touched.phoneNumber && formik.errors.phoneNumber}
                    </Typography>
                  </TextFieldWrapper>
                  <TextFieldWrapper>
                    <TextField
                      fullWidth
                      onBlur={formik.handleBlur}
                      id={'address'}
                      name={'address'}
                      placeholder={'Город, улица, дом, квартира'}
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      error={formik.touched.address && Boolean(formik.errors.address)}
                      // helperText={formik.touched.address && formik.errors.address}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          backgroundColor: 'white',
                          borderRadius: 16,

                        },
                        endAdornment: (
                          <InputAdornment position={'end'}>
                            <Tooltip
                              title={'Для клиентов Москвы и Московской Области'}
                              placement={'top'}
                              arrow
                            >
                              <div>
                                <InfoFormIcon/>
                              </div>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        style: { color: '#BCBFD1' },
                      }}
                    />
                    <Typography className={styles.OrderModal__Error}>
                      {formik.touched.address && formik.errors.address}
                    </Typography>
                  </TextFieldWrapper>
                  <TextFieldWrapper>
                    <CustomDatePicker
                      fieldName={'date'}
                      id={'date'}
                      value={
                        `${formik.values.date
                          .split('/')
                          .filter((l) => l !== '/')
                          .map((l, index) => (index === 1 ? months[Number(l[l.length - 1])] : l))
                          .join(' ')}`
                      }
                    />
                    {/* <div */}
                    {/*   style={{ cursor: 'pointer' }} */}
                    {/*   role={'presentation'} */}
                    {/*   onClick={(event) => openPopUpByField(event)} */}
                    {/*   // className={'orderForm__dateWrapper'} //todo навесить сюда стили */}
                    {/* > */}
                    {/*   <TextField */}
                    {/*     onBlur={formik.handleBlur} */}
                    {/*     variant={'filled'} */}
                    {/*     fullWidth */}
                    {/*     id={'date'} */}
                    {/*     name={'date'} */}
                    {/*     placeholder={'Дата вызова'} */}
                    {/*     value={ */}
                    {/*       selectedRange */}
                    {/*         ? `${formik.values.date */}
                    {/*           .split('/') */}
                    {/*           .filter((l) => l !== '/') */}
                    {/*           .map((l, index) => (index === 1 ? months[Number(l[l.length - 1])] : l)) */}
                    {/*           .join(' ')} ${selectedRange}` */}
                    {/*         : formik.values.date */}
                    {/*           .split('/') */}
                    {/*           .filter((l) => l !== '/') */}
                    {/*           .map((l, index) => (index === 1 ? months[Number(l[l.length - 1])] : l)) */}
                    {/*           .join(' ') */}
                    {/*     } */}
                    {/*     error={formik.touched.date && Boolean(formik.errors.date)} */}
                    {/*     helperText={formik.touched.date && formik.errors.date} */}
                    {/*     InputProps={{ */}
                    {/*       disableUnderline: true, */}
                    {/*       style: { */}
                    {/*         backgroundColor: 'white', */}
                    {/*         border: '1px solid #bcbfd1', */}
                    {/*         borderRadius: 16, */}
                    {/*         pointerEvents: 'none', */}
                    {/*       }, */}
                    {/*       endAdornment: ( */}
                    {/*         <InputAdornment position={'end'}> */}
                    {/*           <CalendarFormIcon/> */}
                    {/*         </InputAdornment> */}
                    {/*       ), */}
                    {/*     }} */}
                    {/*     InputLabelProps={{ */}
                    {/*       style: { color: '#BCBFD1' }, */}
                    {/*     }} */}
                    {/*   /> */}
                    {/* </div> */}
                  </TextFieldWrapper>
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <Checkbox
                      icon={<IconCheckboxNotChecked/>}
                      checkedIcon={<IconCheckboxChecked/>}
                      defaultChecked
                      required
                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                      {...formik.getFieldProps('personalData')}
                    />
                    <p>
                      <span>
                        Согласен на обработку &nbsp;
                      </span>
                      <a
                        href={'/about/personal'}
                        target={'_blank'}
                        rel={'noreferrer'}
                        style={{ textDecoration: 'none', color: 'rgba(130, 128, 235, 1)' }}
                      >
                        персональных данных
                      </a>
                    </p>
                  </Stack>
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <Checkbox
                      icon={<IconCheckboxNotChecked/>}
                      checkedIcon={<IconCheckboxChecked/>}
                      defaultChecked
                      required
                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                      {...formik.getFieldProps('oferta')}
                    />
                    <p>
                      <span>
                        Ознакомлен с &nbsp;
                      </span>
                      <a
                        href={'/about/oferta'}
                        target={'_blank'}
                        rel={'noreferrer'}
                        style={{ textDecoration: 'none', color: 'rgba(130, 128, 235, 1)' }}
                      >
                        публичной офертой
                      </a>
                    </p>
                  </Stack>
                </Stack>
                <button
                  className={styles.OrderModal__Button}
                  type={'submit'}
                >
                  <Typography fontSize={19}>Перейти к оплате</Typography>
                </button>
              </Stack>
              {/* <DateModal */}
              {/*   fullScreen */}
              {/*   setSelectedRange={setSelectedRange} */}
              {/*   selectedRange={selectedRange} */}
              {/*   isOpen={isOpenModal} */}
              {/*   setIsOpen={setIsOpenModal} */}
              {/* /> */}
            </div>
          </form>
        )}
      </Formik>
      <Footer/>
    </Stack>
  );
};
