import { Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { mediaBreakpoints } from '~/ui';

const Wrapper = styled.div`
  background-color: #F6F6FB;
  display: flex;
  flex-direction: column;
  padding: 100px 0;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    padding: 50px 0;
    ul {
      padding-left: 10px;
    }
  }
`;

const Li = styled.li`
  padding-left: 10px;
  padding-bottom: 10px;
`;

export const UserAgreement = () => (
  <Wrapper>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      Политика в области обработки и защиты персональных данных ООО «Техника
      вашего дома»
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      v.1 от 20 мая 2022г.
    </Typography>
    <Typography
      sx={{
        fontSize: ['16px', '24px', '28px', '32px', '32px'],
        marginBottom: '20px',
        marginTop: '20px',
        textAlign: 'center',
        fontWeight: 'bold',
      }}
    >
      ПОЛИТИКА ООО «ТЕХНИКА ВАШЕГО ДОМА» В ОТНОШЕНИИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ
      ДАННЫХ
    </Typography>
    <Typography
      sx={{
        fontSize: ['16px', '24px', '28px', '32px', '32px'],
        marginBottom: '20px',
        marginTop: '20px',
        textAlign: 'center',
        fontWeight: 'bold',
      }}
    >
      ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      <b> Персональные данные</b> – любая информация, относящаяся прямо или
      косвенно к определенному или определяемому физическому лицу (Субъекту
      персональных данных).
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      <b>Информация</b> - сведения (сообщения, данные) независимо от формы их
      представления;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      <b>Автоматизированная обработка персональных данных</b> – Обработка
      персональных данных с помощью средств вычислительной техники.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      <b>Обработка персональных данных</b> – любое действие (операция) или
      совокупность действий (операций), совершаемых с использованием средств
      автоматизации или без использования таких средств, с Персональными
      данными, включая сбор, запись, систематизацию, накопление, хранение,
      уточнение (обновление, изменение), извлечение, использование, передачу
      (распространение, предоставление, доступ), трансграничную передачу,
      обезличивание, блокирование, удаление, уничтожение Персональных данных.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      <b>Оператор персональных данных</b> - государственный орган, муниципальный
      орган, юридическое или физическое лицо, самостоятельно или совместно с
      другими лицами организующие и (или) осуществляющие Обработку персональных
      данных, а также определяющие цели Обработки персональных данных, состав
      персональных данных, подлежащих обработке, действия (операции),
      совершаемые с Персональными данными;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      <b>Субъект персональных данных</b> – физическое лицо, к которому прямо или
      относятся соответствующие Персональные данные.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      <b>Распространение персональных данных</b> - действия, направленные на
      раскрытие Персональных данных неопределенному кругу лиц, (передача
      персональных данных) или на ознакомление с персональными данными
      неограниченного круга лиц, размещение в информационно-телекоммуникационных
      сетях или предоставление доступа к персональным данным каким-либо иным
      способом;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      <b>Предоставление персональных данных</b> - действия, направленные на
      раскрытие Персональных данных определенному лицу или определенному кругу
      лиц;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      <b>Блокирование персональных данных</b> - временное прекращение Обработки
      персональных данных (за исключением случаев, если обработка необходима для
      уточнения Персональных данных);
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      <b>Трансграничная передача персональных данных</b> - передача Персональных
      данных на территорию иностранного государства органу власти иностранного
      государства, иностранному физическому лицу или иностранному юридическому
      лицу;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      <b>Уничтожение персональных данных</b> - действия, в результате которых
      становится невозможным восстановить содержание Персональных данных в
      Информационной системе персональных данных и (или) в результате которых
      уничтожаются материальные носители Персональных данных;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      <b>Обезличивание персональных данных</b> - действия, в результате которых
      становится невозможным без использования дополнительной информации
      определить принадлежность Персональных данных конкретному Субъекту
      персональных данных.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      <b>Информационная система персональных данных</b> - совокупность
      содержащихся в базах данных Персональных данных и обеспечивающих их
      обработку информационных технологий и технических средств
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      <b>Конфиденциальность персональных данных</b> - обязанность Оператора и
      иных лиц, получивших доступ к персональным данным, не раскрывать третьим
      лицам и не распространять персональные данные без согласия субъекта
      персональных данных, если иное не предусмотрено действующим
      законодательством.
    </Typography>
    <Typography
      sx={{
        fontSize: ['16px', '24px', '28px', '32px', '32px'],
        marginBottom: '20px',
        marginTop: '20px',
        textAlign: 'center',
        fontWeight: 'bold',
      }}
    >
      1. ОБЩИЕ ПОЛОЖЕНИЯ
    </Typography>
    <Typography
      sx={{
        fontSize: ['16px', '20px', '22px', '26px', '26px'],
        marginBottom: '20px',
        marginTop: '20px',
        fontWeight: 'bold',
      }}
    >
      1.1. Назначение документа
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      Настоящая Политика в отношении обработки персональных данных (далее –
      Политика) разработана в соответствии с пп. 2 п.1 статьи 18.1 Федерального
      закона от 27 июля 2006 года № 152 «О персональных данных» и определяет
      политику компаний ООО «Техника Вашего Дома» (далее именуемое – Оператор) в
      отношении действий (операций) по обработке персональных данных и защите
      персональных данных, а также основные принципы, условия, способы и цели в
      отношении обработки информации о Субъектах персональных данных, которую
      Оператор и/или его партнеры могут обрабатывать при осуществлении своей
      хозяйственной деятельности.
    </Typography>
    <Typography
      sx={{
        fontSize: ['16px', '20px', '22px', '26px', '26px'],
        marginBottom: '20px',
        marginTop: '20px',
        fontWeight: 'bold',
      }}
    >
      1.2. Область действия
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      Действие настоящей Политики распространяется на все процессы Оператора, в
      рамках которых осуществляется Обработка персональных данных, как с
      использованием средств автоматизации, в том числе в
      информационно-телекоммуникационных сетях, так и без использования таких
      средств.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      Настоящая Политика обязательна для ознакомления и исполнения всеми лицами,
      допущенными Оператором к Обработке персональных данных, и лицами,
      участвующими в организации процессов Обработки персональных данных и
      обеспечения безопасности Персональных данных.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      Для реализации целей настоящей Политики Оператор может разрабатывать и
      утверждать соответствующие положения, регламенты, руководства, приказы и
      иные локальные нормативные акты.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      Использование услуг Оператора подтверждает согласие Субъекта персональных
      данных с настоящей Политикой и указанными в ней условиями Обработки
      персональных данных.
    </Typography>
    <Typography
      sx={{
        fontSize: ['16px', '20px', '22px', '26px', '26px'],
        marginBottom: '20px',
        marginTop: '20px',
        fontWeight: 'bold',
      }}
    >
      1.3. Основные права и обязанности Оператора и субъекта персональных
      данных.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      1.3.1. Субъект персональных данных имеет право на получение информации,
      касающейся Обработки персональных данных, в том числе содержащей:
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      1) подтверждение факта Обработки персональных данных Оператором;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      2) правовые основания и цели Обработки персональных данных;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      3) цели и применяемые Оператором способы Обработки персональных данных;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      4) наименование и место нахождения Оператора, сведения о лицах (за
      исключением работников Оператора), которые имеют доступ к Персональным
      данным или которым могут быть раскрыты Персональные данные на основании
      договора с Оператором или на основании федерального закона;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      5) обрабатываемые Персональные данные, относящиеся к соответствующему
      Субъекту персональных данных, источник их получения, если иной порядок
      представления таких данных не предусмотрен федеральным законом;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      6) сроки Обработки персональных данных, в том числе сроки их хранения;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      7) порядок осуществления Субъектом персональных данных прав,
      предусмотренных настоящим Федеральным законом;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      8) информацию об осуществленной или о предполагаемой трансграничной
      передаче персональных данных;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      9) наименование или фамилию, имя, отчество и адрес лица, осуществляющего
      Обработку персональных данных по поручению Оператора, если Обработка
      персональных данных поручена или будет поручена такому лицу;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      10) иные сведения, предусмотренные Федеральным законом от 27.07.2006 №
      152-ФЗ «О персональных данных» или другими Федеральными законами.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      Право Субъекта персональных данных на доступ к его Персональным данным
      может быть ограничено в соответствии с действующим законодательством.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      1.3.2. Обработка персональных данных в целях продвижения товаров, работ,
      услуг на рынке путем осуществления прямых контактов с потенциальным
      потребителем с помощью средств связи, допускается только при условии
      предварительного согласия на это Субъекта персональных данных. Оператор в
      указанных случаях обязан немедленно прекратить по требованию Субъекта
      персональных данных Обработку персональных данных.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      1.3.3. Субъект персональных данных вправе требовать от Оператора уточнения
      его Персональных данных, их блокирования или уничтожения в случае, если
      Персональные данные являются неполными, устаревшими, неточными, незаконно
      полученными или не являются необходимыми для заявленной цели Обработки
      персональных данных, а также принимать предусмотренные законом меры по
      защите своих прав.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      1.3.4. Если Субъект персональных данных считает, что Оператор осуществляет
      Обработку персональных данных с нарушением требований действующего
      законодательства или иным образом нарушает его права и свободы, то Субъект
      персональных данных вправе обжаловать действия или бездействие Оператора в
      порядке, предусмотренном действующим законодательством.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      1.3.5. Для обеспечения соблюдения установленных законодательством прав
      Субъектов персональных данных Оператором могут быть утверждены
      дополнительные локальные нормативные акты.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      1.3.6. Сведения, указанные в п. 1.3.1. Политики предоставляются Субъекту
      персональных данных или его представителю Оператором при обращении либо
      при получении запроса от Субъекта персональных данных или его
      представителя.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      Запрос направляется по месту нахождения Оператора и должен содержать номер
      основного документа, удостоверяющего личность Субъекта персональных данных
      или его представителя, сведения о дате выдачи указанного документа и
      выдавшем его органе, сведения, подтверждающие участие Субъекта
      персональных данных в отношениях с Оператором (номер договора, дата
      заключения договора, условное словесное обозначение и (или) иные
      сведения), либо сведения, иным образом подтверждающие факт Обработки
      персональных данных Оператором, подпись Субъекта персональных данных или
      его представителя.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      В случаях, если объем персональных данных, обрабатываемых Оператором
      меньше, чем указан в настоящем пункте, то следует руководствоваться
      принципом «минимизации персональных данных». Указанный принцип позволяет
      направить запрос с указанием только тех персональных данных, которые
      позволяют однозначно идентифицировать Субъекта персональных данных.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      Запрос может быть направлен в форме электронного документа и подписан
      электронной подписью в соответствии с действующим законодательством
      Российской Федерации.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      1.3.7. В случае, если сведения, указанные в п. 1.3.1. Политики, а также
      обрабатываемые Персональные данные были предоставлены для ознакомления
      Субъекту персональных данных по его запросу, Субъект персональных данных
      вправе обратиться повторно к Оператору или направить ему повторный запрос
      в целях получения сведений, указанных в п. 1.3.1. Политики и ознакомления
      с такими Персональными данными не ранее чем через тридцать дней после
      первоначального обращения или направления первоначального запроса, если
      более короткий срок не установлен федеральным законом, принятым в
      соответствии с ним нормативным правовым актом или договором, стороной
      которого либо выгодоприобретателем или поручителем по которому является
      Субъект персональных данных.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      1.3.8. Субъект персональных данных вправе обратиться повторно к Оператору
      или направить ему повторный запрос в целях получения сведений, указанных
      п. 1.3.1. Политики, а также в целях ознакомления с обрабатываемыми
      Персональными данными до истечения срока, указанного в п. 1.3.7. Политики
      в случае, если такие сведения и (или) обрабатываемые Персональные данные
      не были предоставлены ему для ознакомления в полном объеме по результатам
      рассмотрения первоначального обращения. Повторный запрос наряду со
      сведениями, указанными в п. 1.3.6. Политики, должен содержать обоснование
      направления повторного запроса.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      1.3.9. Оператор вправе мотивировано отказать Субъекту персональных данных
      в выполнении повторного запроса, не соответствующего условиям,
      предусмотренным пп. 1.3.7, 1.3.8. Политики и действующим
      законодательством.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      1.3.10. В соответствии с требованиями Федерального закона № 152-ФЗ «О
      персональных данных» Оператор обязан:
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - осуществлять Обработку персональных данных с соблюдением принципов и
      правил, предусмотренных Федеральным законом № 152-ФЗ «О персональных
      данных»;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - не раскрывать третьим лицам и не распространять персональные данные без
      согласия Субъекта персональных данных, если иное не предусмотрено
      Федеральным законом № 152-ФЗ «О персональных данных»;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - предоставить доказательство получения согласия Субъекта персональных
      данных на обработку его персональных данных или доказательство наличия
      оснований, в соответствии с которыми такое согласие не требуется;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - в случаях, предусмотренных Федеральным законом № 152-ФЗ «О персональных
      данных», осуществлять Обработку персональных данных только с согласия в
      письменной форме Субъекта персональных данных;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - предоставлять Субъекту персональных данных по его запросу информацию,
      касающуюся Обработки персональных данных, либо на законных основаниях
      предоставить отказ в предоставлении указанной информации и дать в
      письменной форме мотивированный ответ, содержащий ссылку на положения
      Федерального закона № 152-ФЗ «О персональных данных», являющееся
      основанием для такого отказа, в порядке и сроки, предусмотренные
      действующим законодательством, настоящей Политикой и иными локальными
      нормативными актами Оператора.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - если предоставление персональных данных является обязательным в
      соответствии с Федеральным законом, разъяснить Субъекту персональных
      данных юридические последствия отказа предоставить его Персональные
      данные;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - принимать необходимые правовые, организационные и технические меры или
      обеспечивать их принятие для защиты Персональных данных от неправомерного
      или случайного доступа к ним, уничтожения, изменения, блокирования,
      копирования, предоставления, распространения Персональных данных, а также
      от иных неправомерных действий в отношении Персональных данных. Описание
      принимаемых мер приведено в разделе 6 настоящей Политики;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - при сборе Персональных данных, в том числе посредством
      информационно-телекоммуникационной сети "Интернет", Оператор обязан
      обеспечить запись, систематизацию, накопление, хранение, уточнение
      (обновление, изменение), извлечение Персональных данных граждан Российской
      Федерации с использованием баз данных, находящихся на территории
      Российской Федерации, за исключением случаев предусмотренном действующим
      законодательством.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - по требованию Субъекта персональных данных внести изменения в
      обрабатываемые Персональные данные, или уничтожить их, если Персональные
      данные являются неполными, неточными, неактуальными, незаконно полученными
      или не являются необходимыми для заявленной цели в порядке и сроки,
      предусмотренные действующим законодательством, настоящей Политикой и иными
      локальными нормативными актами Оператора.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - организовывать прием и обработку обращений и запросов Субъектов
      персональных данных или их представителей и (или) осуществлять контроль за
      приемом и обработкой таких обращений и запросов. В т.ч. в этих целях
      Оператор вправе вести «Журнал учета обращений субъектов персональных
      данных», в котором должны фиксироваться запросы Субъектов персональных
      данных на получение Персональных данных, а также факты предоставления
      Персональных данных по этим запросам.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - уведомлять Субъекта персональных данных об Обработке персональных данных
      в том случае, если Персональные данные были получены не от Субъекта
      персональных данных. Исключение составляют следующие случаи:
    </Typography>
    <ul>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          Субъект персональных данных уведомлен об осуществлении Обработки
          персональных данных Оператором;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          Персональные данные получены Оператором на основании Федерального
          закона или в связи с исполнением договора, стороной которого либо
          выгодоприобретателем или поручителем, по которому является Субъект
          персональных данных;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          Персональные данные сделаны общедоступными Субъектом персональных
          данных или получены из общедоступного источника;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          Оператор осуществляет Обработку персональных данных для статистических
          или иных исследовательских целей, для осуществления профессиональной
          деятельности журналиста либо научной, литературной или иной творческой
          деятельности, если при этом не нарушаются права и законные интересы
          Субъекта персональных данных;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          предоставление Субъекту персональных данных сведений, содержащихся в
          уведомлении об обработке персональных данных, нарушает права и
          законные интересы третьих лиц.
        </Typography>
      </Li>
    </ul>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - в случае выявления неправомерной Обработки персональных данных или
      неточных Персональных данных, устранить выявленные нарушения в
      соответствии с порядком и сроками, установленными Федеральным законом №
      152-ФЗ «О персональных данных».
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - в случае достижения целей Обработки персональных данных незамедлительно
      прекратить Обработку персональных данных и уничтожить соответствующие
      Персональные данные в срок, не превышающий тридцати дней с даты достижения
      цели Обработки персональных данных, если иное не предусмотрено договором,
      стороной которого, выгодоприобретателем или поручителем по которому
      является Субъект персональных данных, иным соглашением между Оператором и
      Субъектом персональных данных (в т.ч. соответствующим согласием Субъекта
      персональных данным на Обработку персональных данных) либо если Оператор
      не вправе осуществлять Обработку персональных данных без согласия Субъекта
      персональных данных на основаниях, предусмотренных №152-ФЗ «О персональных
      данных» или другими Федеральными законами.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - в случае отзыва Субъектом персональных данных согласия на Обработку
      своих персональных данных прекратить Обработку персональных данных и
      уничтожить персональные данные в срок, не превышающий тридцати дней с даты
      поступления указанного отзыва, если иное не предусмотрено соглашением
      между Оператором и Субъектом персональных данных либо действующим
      законодательством. Об Уничтожении персональных данных Оператор обязан
      уведомить Субъекта персональных данных.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - в случае поступления требования Субъекта персональных данных о
      прекращении Обработки персональных данных в целях продвижения товаров,
      работ, услуг на рынке прекратить Обработку персональных данных в порядке,
      предусмотренном действующим законодательством, настоящей Политикой и иными
      локальными нормативными актами Оператора.
    </Typography>
    <Typography
      sx={{
        fontSize: ['16px', '20px', '22px', '26px', '26px'],
        marginBottom: '20px',
        marginTop: '20px',
        fontWeight: 'bold',
      }}
    >
      1.4. Утверждение и пересмотр Политики
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      Настоящая Политика вступает в силу с момента ее утверждения Генеральным
      директором соответствующего Оператора и действует бессрочно до замены ее
      новой Политикой. Обеспечение неограниченного доступа к Политике
      реализуется путем ее публикации на сайте/сайтах Оператора в сети Интернет,
      либо иным способом.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      Ответственным за изменение настоящей Политики является работник Оператора,
      назначенный ответственным за организацию Обработки персональных данных.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      Измененная Политика утверждается приказом Генерального директора
      соответствующего Оператора.
    </Typography>
    <Typography
      sx={{
        fontSize: ['16px', '20px', '22px', '26px', '26px'],
        marginBottom: '20px',
        marginTop: '20px',
        fontWeight: 'bold',
      }}
    >
      2. ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      Оператор производит обработку только тех Персональных данных, которые
      необходимы для
    </Typography>
    <ul>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          обеспечения соблюдения Конституции Российской Федерации,
          законодательных и иных нормативных правовых актов Российской
          Федерации, локальных нормативных актов Оператора;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          ведения бухгалтерского и налогового учета;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          выполнения обязательств Оператора по гражданско-правовым договорам и
          иным сделкам
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          организации операционной деятельности Оператора и иных процессов, в
          которых участвует Оператор;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          обеспечения функционирования принадлежащей Оператору инфраструктуры;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          организация пользования Интернет и Интранет-ресурсами Оператора;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          подготовки доверенностей;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          защиты прав и законных интересов Оператора и его работников в судах,
          органах по разрешению споров, а также органах административной
          юрисдикции;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          предоставления доступа к сервису физическим и юридическим лицам;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          обработки жалоб/сообщений посетителей и третьих лиц;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          сбора и анализа статистических данных и показателей Оператора;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          реализации целей, указанных в утвержденных Оператором типовых
          согласиях на обработку персональных данных, Положении об обработке и
          защите персональных данных, а также иных локальных нормативных актах
          Оператора;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          реализации иных целей, предусмотренных заключаемыми Оператором
          договорами, соглашениями и другими документами.
        </Typography>
      </Li>
    </ul>
    <Typography
      sx={{
        fontSize: ['16px', '24px', '28px', '32px', '32px'],
        marginBottom: '20px',
        marginTop: '20px',
        textAlign: 'center',
        fontWeight: 'bold',
      }}
    >
      3. ПРАВОВЫЕ ОСНОВАНИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      Правовыми основаниями обработки Оператором персональных данных в порядке,
      предусмотренном настоящей Политикой, являются
    </Typography>
    <ul>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          Трудовой кодекс Российской Федерации;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          Налоговый кодекс Российской Федерации;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          Федеральный закон № 402-ФЗ «О бухгалтерском учете»;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          Федеральный закон № 125-ФЗ «Об архивном деле в Российской Федерации»;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          Приказ Минкультуры России от 25.08.2010 № 558 «Об утверждении «Перечня
          типовых управленческих архивных документов, образующихся в процессе
          деятельности государственных органов, органов местного самоуправления
          и организаций, с указанием сроков хранения»;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          Федеральный закон "О персональных данных" от 27.07.2006 N 152-ФЗ;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          Федеральный закон от 27 июля 2006 года № 149 «Об информации,
          информационных технологиях и о защите информации»
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          Указ Президента РФ от 6 марта 1997 г. N 188 "Об утверждении перечня
          сведений конфиденциального характера";
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          Постановление Правительства РФ от 15 сентября 2008 г. N 687 "Об
          утверждении Положения об особенностях обработки персональных данных,
          осуществляемой без использования средств автоматизации";
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          Постановление Правительства РФ от 01.11.2012 N 1119 "Об утверждении
          требований к защите персональных данных при их обработке в
          информационных системах персональных данных";
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          Постановление Правительства Российской Федерации от 6 июля 2008 г. №
          512 «Об утверждении требований к материальным носителям биометрических
          персональных данных и технологиям хранения таких данных вне
          информационных систем персональных данных»;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          Приказ Роскомнадзора от 05.09.2013 N 996 "Об утверждении требований и
          методов по обезличиванию персональных данных";
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          Приказ ФСТЭК России от 18 февраля 2013 г. № 21 «Об утверждении состава
          и содержания организационных и технических мер по обеспечению
          безопасности персональных данных при их обработке в информационных
          системах персональных данных»;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          иные нормативно-правовые актов Российской Федерации, в рамках
          осуществления и выполнения, возложенных законодательством Российской
          Федерации на Оператора функций, полномочий и обязанностей;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          Учредительные документы Оператора;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          Локальные нормативные акты Оператора, регулирующие вопросы Обработки
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          персональных данных;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          Заключаемые Оператором гражданско-правовые и иные договоры и
          соглашения;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          Согласия Субъектов персональных данных на обработку персональных
          данных;
        </Typography>
      </Li>
    </ul>
    <Typography
      sx={{
        fontSize: ['16px', '24px', '28px', '32px', '32px'],
        marginBottom: '20px',
        marginTop: '20px',
        textAlign: 'center',
        fontWeight: 'bold',
      }}
    >
      4. ОБЪЕМ И КАТЕГОРИИ ОБРАБАТЫВАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ, КАТЕГОРИИ
      СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ.
    </Typography>
    <Typography
      sx={{
        fontSize: ['16px', '20px', '22px', '26px', '26px'],
        marginBottom: '20px',
        marginTop: '20px',
        fontWeight: 'bold',
      }}
    >
      4.1 Оператором может производиться Обработка персональных данных следующих
      категорий Субъектов персональных данных:
    </Typography>
    <ul>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          физические лица – работники Оператора;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          физические лица – представители работников Оператора;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          физические лица – работники контрагентов и третьих лиц;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          физические лица – пользователи MyTech (клиенты Оператора);
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          физические лица – посетители сайтов, мобильных приложений Оператора
          и/или иных принадлежащих Оператору Интернет-ресурсов;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          физические лица, заключившие гражданско-правовые договоры с
          Оператором;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          физические лица – представители/работники клиентов и контрагентов
          Оператора;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          физические лица в цепочке собственников/участников контрагентов
          Оператора;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          Потенциальные контрагенты (физические лица),
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          учредители/участники/акционеры/представители (физические лица)
          потенциальных контрагентов;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          адвокаты, нотариусы, осуществляющие взаимодействие с Оператором;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          авторы письменных и иных обращений в адрес Оператора;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          иные Субъекты персональных данных (для обеспечения реализации
          целейОбработки, указанных в разделе 2 Политики)
        </Typography>
      </Li>
    </ul>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      4.2. Перечень и объем Персональных данных, обрабатываемых Оператором,
      определяется в соответствии с законодательством Российской Федерации,
      локальными нормативными актами Оператора и подготавливаемыми на их
      основании документами отдельно для каждого процесса, связанного с
      Обработкой персональных данных, с учетом целей Обработки персональных
      данных, указанных в разделе 2 Политики.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      4.3. В состав Персональных данных, обрабатываемых Оператором, могут
      входить:
    </Typography>
    <ul>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          персональная информация, такая как имя, отчество, фамилия, номер
          телефона, адрес и возраст;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          электронные данные (HTTP-заголовки, IP-адрес, файлы cookie,
          веб-маяки/пиксельные теги, данные об идентификаторе браузера,
          информация об аппаратном и программном обеспечении, данные сети
          wi-fi);
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          дата и время осуществления доступа к ресурсам Оператора;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          Иная информация, полученная в рамках взаимодействия с Оператором.
        </Typography>
      </Li>
    </ul>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      Обработка персональных данных специальных категорий, касающихся расовой,
      принадлежности, политических взглядов, религиозных или философских
      убеждений, интимной жизни субъектов персональных данных, Оператором не
      осуществляется.
    </Typography>
    <Typography
      sx={{
        fontSize: ['16px', '24px', '28px', '32px', '32px'],
        marginBottom: '20px',
        marginTop: '20px',
        textAlign: 'center',
        fontWeight: 'bold',
      }}
    >
      5. ПОРЯДОК И УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
    </Typography>
    <Typography
      sx={{
        fontSize: ['16px', '20px', '22px', '26px', '26px'],
        marginBottom: '20px',
        marginTop: '20px',
        fontWeight: 'bold',
      }}
    >
      5.1. Общий порядок Обработки персональных данных
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      При организации Обработки персональных данных Оператором выполняются
      следующие принципы и условия:
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - Обработка персональных данных осуществляется на законной и справедливой
      основе;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - Обработка персональных данных ограничивается достижением конкретных,
      заранее определенных и законных целей. Не допускается Обработка
      персональных данных, несовместимая с целями сбора Персональных данных;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - Не допускается объединение баз данных, содержащих Персональные данные,
      Обработка персональных данных которых осуществляется в целях,
      несовместимых между собой;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - Обработка персональных данных допустима только в отношении Персональных
      данных, которые отвечают целям Обработки персональных данных;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - Содержание и объем обрабатываемых Персональных данных соответствуют
      заявленным целям их обработки. Обрабатываемые Персональные данные не
      являются избыточными по отношению к заявленным целям Обработки
      персональных данных;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - При обработке персональных данных обеспечивается точность Персональных
      данных, их достаточность и актуальность по отношению к целям Обработки
      персональных данных;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - Содержание и объем обрабатываемых Персональных данных соответствуют
      заявленным целям обработки персональных данных;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - Уничтожению персональных данных либо обезличиванию персональных данных
      подлежат Персональные данные, цели обработки персональных данных которых
      достигнуты или утрачена необходимость в достижении этих целей.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      Оператор в своей деятельности исходит из того, что Субъект персональных
      данных предоставляет точную и достоверную информацию, а во время
      взаимодействия с Оператором извещает представителей Оператора об изменении
      своих Персональных данных.
    </Typography>
    <Typography
      sx={{
        fontSize: ['16px', '20px', '22px', '26px', '26px'],
        marginBottom: '20px',
        marginTop: '20px',
        fontWeight: 'bold',
      }}
    >
      5.2. Перечень действий с Персональными данными и способы Обработки
      персональных данных
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      Оператор может осуществлять обработку в т.ч.: сбор, запись,
      систематизацию, накопление, хранение, уточнение (обновление, изменение),
      извлечение, использование, передачу (распространение, предоставление,
      доступ), трансграничную передачу, обезличивание, блокирование, удаление,
      уничтожение Персональных данных.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      Обработка персональных данных осуществляется Оператором следующими
      способами:
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - Неавтоматизированная Обработка персональных данных;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - Автоматизированная Обработка персональных данных с передачей полученной
      информации по информационно-телекоммуникационным сетям или без таковой;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - Смешанная Обработка персональных данных.
    </Typography>
    <Typography
      sx={{
        fontSize: ['16px', '20px', '22px', '26px', '26px'],
        marginBottom: '20px',
        marginTop: '20px',
        fontWeight: 'bold',
      }}
    >
      5.3. Условия Обработки персональных данных Субъектов персональных данных и
      ее передачи третьим лицам.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      Оператор обрабатывает и хранит Персональные данные Субъектов персональных
      данных в соответствии с локальными нормативными актами, разработанными
      согласно законодательству РФ.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      Порядок и сроки хранения Персональных данных, обрабатываемых Оператором,
      определяются в соответствии с законодательством Российской Федерации,
      локальными нормативными актами Оператора и подготавливаемыми на их
      основании документами отдельно для каждого процесса, связанного с
      Обработкой персональных данных, с учетом целей Обработки персональных
      данных, указанных в разделе 2 Политики.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      В отношении Персональных данных Субъекта персональных данных
      обеспечивается их конфиденциальность, целостность и доступность. Передача
      Персональных данных третьим лицам для выполнения договорных обязательств
      осуществляется только с согласия Субъекта персональных данных, а для
      выполнения требований законодательства РФ – в рамках установленной
      законодательством процедуры. В случае реорганизации, продажи или иной
      передачи бизнеса (полностью или части) Оператора к приобретателю переходят
      все обязательства по соблюдению условий настоящей Политики применительно к
      получаемым им Персональным данным.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      Оператор может поручить Обработку персональных данных другому лицу при
      выполнении следующих условий:
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - получено согласие Субъекта персональных данных на поручение Обработки
      персональных данных другому лицу;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - поручение Обработки персональных данных осуществляется на основании
      заключаемого с этим лицом договора, разработанного с учетом требований
      Федерального закона РФ от 27 июля 2006 года № 152 «О персональных данных».
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - иных случаях, предусмотренных действующим законодательством.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      Лицо, осуществляющее Обработку персональных данных по поручению Оператора,
      обязано соблюдать принципы и правила Обработки персональных данных и несет
      ответственность перед Оператором. Оператор несет ответственность перед
      Субъектом персональных данных за действия уполномоченного лица, которому
      Оператор поручил Обработку персональных данных.
    </Typography>
    <Typography
      sx={{
        fontSize: ['16px', '24px', '28px', '32px', '32px'],
        marginBottom: '20px',
        marginTop: '20px',
        textAlign: 'center',
        fontWeight: 'bold',
      }}
    >
      6. МЕРЫ, ПРИМЕНЯЕМЫЕ ОПЕРАТОРОМ ДЛЯ ЗАЩИТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      Оператор принимает необходимые и достаточные организационные и технические
      меры для защиты Персональных данных Субъектов персональных данных от
      неправомерного или случайного доступа к ним, уничтожения, изменения,
      блокирования, копирования, распространения, а также от иных неправомерных
      действий с ней третьих лиц.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      К основным методам и способам обеспечения безопасности Персональных данных
      относятся:
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - назначение Оператором, лица, ответственного за организацию Обработки
      персональных данных;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - издание Оператором, документов, определяющих политику Оператора в
      отношении Обработки персональных данных, локальных актов по вопросам
      Обработки персональных данных, а также локальных актов, устанавливающих
      процедуры, направленные на предотвращение и выявление нарушений
      законодательства Российской Федерации, устранение последствий таких
      нарушений;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - применение правовых, организационных и технических мер по обеспечению
      безопасности Персональных данных в соответствии действующим
      законодательством, в том числе:
    </Typography>
    <ul>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          определением угроз безопасности Персональных данных при Обработке
          персональных данных в Информационных системах персональных данных;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          применением организационных и технических мер по обеспечению
          безопасности Персональных данных при Обработке персональных данных в
          Информационных системах персональных данных, необходимых для
          выполнения требований к защите Персональных данных, исполнение которых
          обеспечивает установленные Правительством Российской Федерации уровни
          защищенности Персональных данных;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          применением прошедших в установленном порядке процедуру оценки
          соответствия средств защиты информации;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          оценкой эффективности принимаемых мер по обеспечению безопасности
          Персональных данных до ввода в эксплуатацию Информационной системы
          персональных данных;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          учетом машинных носителей Персональных данных;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          обнаружением фактов несанкционированного доступа к Персональным данным
          и принятием мер к предотвращению несанкционированного доступа;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          восстановлением Персональных данных, модифицированных или уничтоженных
          вследствие несанкционированного доступа к ним;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          установлением правил доступа к Персональным данным, обрабатываемым в
          Информационной системе персональных данных, а также обеспечением
          регистрации и учета всех действий, совершаемых с Персональными данными
          в Информационной системе персональных данных;
        </Typography>
      </Li>
      <Li>
        <Typography
          sx={{
            fontSize: ['14px', '16px', '18px', '20px', '20px'],
            marginBottom: '12px',
          }}
        >
          контролем за принимаемыми мерами по обеспечению безопасности
          Персональных данных и уровня защищенности Информационных систем
          персональных данных;
        </Typography>
      </Li>
    </ul>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - осуществление внутреннего контроля и (или) аудита соответствия Обработки
      персональных данных действующему законодательству, требованиям к защите
      Персональных данных, политике Оператора в отношении Обработки персональных
      данных, локальным актам Оператора;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - оценка вреда, который может быть причинен Субъектам персональных данных
      в случае нарушения действующего законодательства, соотношение указанного
      вреда и принимаемых Оператором мер, направленных на обеспечение выполнения
      обязанностей, предусмотренных действующим законодательством;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - ознакомление работников Оператора, непосредственно осуществляющих
      Обработку персональных данных, с положениями законодательства Российской
      Федерации о Персональных данных, в том числе требованиями к защите
      Персональных данных, документами, определяющими политику Оператора в
      отношении Обработки персональных данных, локальными актами по вопросам
      Обработки персональных данных, и (или) обучение указанных работников;
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - Обеспечение неограниченного доступа к документу, определяющему политику
      Оператора в отношении Обработки персональных данных, к сведениям о
      реализуемых требованиях к защите Персональных данных. Опубликование в
      соответствующих информационно-телекоммуникационных сетях документа,
      определяющий политику Оператора в отношении Обработки персональных данных,
      и сведения о реализуемых требованиях к защите Персональных данных, а также
      обеспечение возможности доступа к указанному документу с использованием
      средств соответствующей информационно-телекоммуникационной сети.
    </Typography>
    <Typography
      sx={{
        fontSize: ['14px', '16px', '18px', '20px', '20px'],
        marginBottom: '12px',
      }}
    >
      - Оператор вправе принимать иные меры необходимые для защиты Персональных
      данных в соответствии с действующим законодательством и локальными
      нормативными актами Оператора.
    </Typography>
  </Wrapper>
);
