import React from 'react';

export const IconCheckboxChecked: React.FC = () => (
  <svg
    width={'24'}
    height={'24'}
    viewBox={'0 0 24 24'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <rect
      x={'2'}
      y={'2'}
      width={'20'}
      height={'20'}
      rx={'4'}
      fill={'#E1E4FC'}
      stroke={'#E1E4FC'}
      strokeWidth={'1.66'}
      strokeLinejoin={'bevel'}
    />
    <path
      d={'M16.7865 9.4739L16.527 9.21438C16.2415 8.92854 15.7742 8.92854 15.4886 9.21438L11.0768 13.6262L9.51152 12.0612C9.22629 11.7757 8.75867 11.7757 8.47344 12.0615L8.21392 12.3207C7.92869 12.6063 7.92869 13.0736 8.21392 13.3591L10.5563 15.7033C10.8418 15.9891 11.3091 15.9891 11.5946 15.7033L16.7865 10.5123C17.0712 10.2267 17.0712 9.75943 16.7865 9.4739Z'}
      fill={'#6156D5'}
    />
  </svg>
);
