import { Stack, Typography } from '@mui/material';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Color } from '~/ui/common';
import { ArrowDown } from './arrow-down';

type UnitCardFinalProps = {
  title: string;
  price: string;
  onMoreClick: () => void;
};

const useStyles = createUseStyles({
  UnitCardFinal: {
    'maxWidth': 450,
    'backgroundColor': Color.White,
    'borderRadius': 20,
    'padding': '32px 24px 16px 24px',
    '@media(max-width: 1032px)': {
      marginBottom: 20,
      padding: '10px 7px 10px 10px',
      minHeight: '122px',
    },
  },
});

export const UnitCardFinal = (props: UnitCardFinalProps) => {
  const styles = useStyles();
  return (
    <Stack
      spacing={1.4}
      justifyContent={'space-between'}
      className={styles.UnitCardFinal}
    >
      <Stack
        spacing={4}
        direction={'row'}
      >
        <Typography
          width={'70%'}
          fontWeight={'400'}
          fontSize={23}
        >
          {props.title}
        </Typography>
        <Typography
          fontWeight={'500'}
          fontSize={23}
          color={'#8280EB'}
        >
          {props.price}
        </Typography>
      </Stack>
      <Stack
        spacing={1}
        alignItems={'center'}
        direction={'row'}
        textAlign={'center'}
        // sx={{border: '1px solid red'}}
      >
        <Typography
          sx={{ cursor: 'pointer' }}
          color={Color.Gray2}
          fontSize={19}
          onClick={props.onMoreClick}
        >
          Подробнее
        </Typography>
        <div style={{ cursor: 'pointer', marginTop: '3px' }}>
          <ArrowDown/>
        </div>
      </Stack>
    </Stack>
  );
};
