import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(
  { viewBox: '0 0 24 25', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
)`
  width: 24px;
  height: 24px;
`;

export const CustomDatePickerRightArrowIcon = () => (
  <Svg>
    <path d={'M9.625 7.88965L14.625 12.8896L9.625 17.8896'} stroke={'#232D42'} strokeWidth={'2'} strokeLinecap={'round'} strokeLinejoin={'round'}/>
    <path d={'M1.625 13.3896L1.625 12.3896L-0.375 12.3896L-0.375 13.3896L1.625 13.3896ZM22.625 12.3896L22.625 13.3896L24.625 13.3896L24.625 12.3896L22.625 12.3896ZM22.625 13.3896C22.625 19.1886 17.924 23.8896 12.125 23.8896L12.125 25.8896C19.0286 25.8896 24.625 20.2932 24.625 13.3896L22.625 13.3896ZM12.125 1.88965C17.924 1.88965 22.625 6.59066 22.625 12.3896L24.625 12.3896C24.625 5.48609 19.0286 -0.110351 12.125 -0.110351L12.125 1.88965ZM1.625 12.3896C1.625 6.59066 6.32601 1.88965 12.125 1.88965L12.125 -0.110351C5.22144 -0.110352 -0.375 5.48609 -0.375 12.3896L1.625 12.3896ZM-0.375 13.3896C-0.375 20.2932 5.22144 25.8896 12.125 25.8896L12.125 23.8896C6.32601 23.8896 1.625 19.1886 1.625 13.3896L-0.375 13.3896Z'} fill={'black'} fillOpacity={'0.08'}/>
  </Svg>
);
