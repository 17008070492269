import { Stack, Typography } from '@mui/material';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Color } from '~/ui';
import { DoneImage } from './done-image';

type ModalProps = {
  features: Array<string>;
};

const useStyles = createUseStyles({
  WashingMachineModal: {
    'borderRadius': 20,
    'position': 'relative',
    'padding': '10px 45px 15px 54px',
    '@media(max-width: 1032px)': {
      padding: '3px 10px 5px 5px',
    },
  },
  WashingMachineModal__Close: {
    position: 'absolute',
    top: 36,
    right: 36,
    cursor: 'pointer',
  },
  WashingMachineModal__Button: {
    backgroundColor: '#1B2040',
    color: Color.White,
    padding: '12px 67px 12px 67px',
    borderRadius: 12,
    border: 'none',
    cursor: 'pointer',
  },
});

export const Modal = (props: ModalProps) => {
  const styles = useStyles();
  return (
    <div
      className={styles.WashingMachineModal}
    >
      <Stack spacing={3.5}>
        <Typography
          component={'div'}
          fontSize={14}
          fontWeight={400}
          color={Color.Gray3}
        >
          ЧТО ВХОДИТ
        </Typography>
        {props.features.map((feature, index) => (
          <Stack
            key={index}
            spacing={3.5}
          >
            <Stack
              direction={'row'}
              spacing={1.2}
              alignItems={'start'}
            >
              <div style={{ width: 30 }}>
                <DoneImage/>
              </div>
              <Typography
                component={'div'}
                fontSize={18}
                fontWeight={400}
                color={'rgba(27, 32, 64, 1)'}
              >
                {feature}
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </div>
  );
};
