import { Stack, Typography, Divider } from '@mui/material';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Color } from '~/ui';

const useStyles = createUseStyles({
  DialogTitleInfo: {
    'fontSize': '23px',
    '@media(max-width: 470px)': {
      fontSize: '15px',
    },
  },
  DialogTitleInfo__TitleBlock: {
    'padding': '0 30px 0 35px',
    '@media(max-width: 470px)': {
      padding: '0px',
    },
  },
  DialogTitleInfo__ButtonBlock: {
    'padding': '0 30px 0 35px',
    '@media(max-width: 570px)': {
      // alignSelf: 'center',
      // justifyContent: 'space-around',
      // alignItems: 'center',
      // padding: '0px',
    },
  },
  DialogTitleInfo__ModalButton: {
    // 'marginLeft': 90,
    'backgroundColor': '#1B2040',
    'color': Color.White,
    'padding': '12px 67px 12px 67px',
    'borderRadius': 12,
    'border': 'none',
    'cursor': 'pointer',
    '@media(max-width: 470px)': {
      // marginLeft: 20,
      alignSelf: 'center',
      padding: '6px 30px 6px 30px',
    },
    '&:hover': {
      backgroundColor: 'rgba(9, 14, 43, 1)',
    },
    '&:active': {
      backgroundColor: 'rgba(6, 10, 33, 1)',
    },
  },
  DialogTitleInfo__Divider: {
    // 'margin': '0 -40px 0 -40px',
    // '@media(max-width: 1032px)': {
    //   margin: '0 -7px 0 -7px',
    // },
    // '@media(max-width: 500px)': {
    //   // marginBottom: 20,
    //   margin: 0,
    // },
  },
});

type DialogTitleInfoProps = {
  title: string;
  price: string;
  onButtonClick?: () => void;
  icon: React.ReactNode;
};

export const DialogTitleInfo = (props: DialogTitleInfoProps) => {
  const styles = useStyles();
  return (
    <Stack spacing={3.5}>
      <Stack
        sx={{ padding: '0 40px 0 40px' }}
        marginTop={10}
        spacing={2.4}
      >
        <Stack
          direction={'row'}
          spacing={2.5}
          className={styles.DialogTitleInfo__TitleBlock}
          alignItems={'center'}
        >
          {props.icon}
          <Typography
            fontWeight={'normal'}
            fontSize={'1em'}
          >
            {props.title}
          </Typography>
        </Stack>
        <Stack
          direction={'row'}
          flexWrap={'wrap'}
          alignItems={'center'}
          justifyContent={'space-between'}
          className={styles.DialogTitleInfo__ButtonBlock}
        >
          <Typography
            color={'#7E2FED'}
            fontWeight={'bold'}
            fontSize={'1.4em'}
          >
            {props.price}
          </Typography>
          <button
            className={styles.DialogTitleInfo__ModalButton}
            onClick={props.onButtonClick}
          >
            <Typography fontSize={'1em'}>Заказать</Typography>
          </button>
        </Stack>
      </Stack>
      <Stack className={styles.DialogTitleInfo__Divider}>
        <Divider/>
      </Stack>
    </Stack>
  );
};
