import {
  Stack, Divider, Typography, Link,
} from '@mui/material';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Color } from '~/ui/common';
import { ArrowDown } from './arrow-down';

type UnitCardProps = {
  icon: React.ReactNode;
  title: string | React.ReactNode;
  price: string;
  onMoreClick: () => void;
  onButtonClick?: () => void;
};

const useStyles = createUseStyles({
  UnitCard: {
    fontSize: '23px',
    // maxWidth: 450,
    // minHeight: '200px',
    backgroundColor: Color.White,
    borderRadius: 20,
    boxShadow: '0px 7px 14px 7px rgba(122, 112, 234, 0.15)',
    // padding: '32px 24px 16px 24px',
    // '@media(max-width: 1032px)': {
    //   width: '100%',
    //   marginBottom: 20,
    //   padding: '10px 7px 10px 10px',
    //   minHeight: '122px',
    // },
    // '@media(max-width: 500px)': {
    //   // marginBottom: 20,
    //   padding: '6px 0 6px 0',
    // },
  },
  UnitCard__Top: {
    // '@media(max-width: 340px)': {
    //   display: 'flex',
    //   flexDirection: 'row',
    //   justifyContent: 'space-between',
    // },
  },
  UnitCard__TitleBlock: {
    // minWidth: 350,
    paddingRight: '30px',
    // '@media(max-width: 1032px)': {
    //   minWidth: 300,
    //   // width: '100%',
    //   fontSize: '19px',
    // },
    // '@media(max-width: 500px)': {
    //   minWidth: 150,
    // },
    // '@media(max-width: 340px)': {
    //   paddingRight: '0',
    //   fontSize: '12px',
    // },
  },
  UnitCard__Title: {
    // 'fontSize': '50px',
    // '@media(max-width: 1032px)': {
    //   // width: '100%',
    //   fontSize: '19px',
    // },
    // '@media(max-width: 340px)': {
    //   fontSize: '12px',
    // },
  },
  UnitCard__Divider: {
    margin: '0 -24px 0 -24px',
    // '@media(max-width: 1032px)': {
    //   margin: '0 -7px 0 -7px',
    // },
    // '@media(max-width: 500px)': {
    //   // marginBottom: 20,
    //   margin: 0,
    // },
  },
  UnitCard__Bottom: {
    // '@media(max-width: 340px)': {
    //   display: 'flex',
    //   flexDirection: 'row',
    //   justifyContent: 'space-between',
    //   padding: '0 6px 0 6px',
    // },
  },
  UnitCard__Button: {
    'backgroundColor': 'rgba(225, 228, 250, 1)',
    'padding': '12px 67px 12px 67px',
    'borderRadius': 12,
    'border': 'none',
    'cursor': 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(211, 215, 242, 1)',
    },
    '&:pressed': {
      backgroundColor: 'rgba(188, 192, 221, 1)',
    },
    '@media(max-width: 700px)': {
      padding: '12px 51px 12px 51px',
    },
    '@media(max-width: 600px)': {
      padding: '12px 45px 12px 45px',
    },
    // '@media(max-width: 340px)': {
    //   padding: '5px 10px 5px 10px',
    // },
  },
});

export const UnitCard = (props: UnitCardProps) => {
  const styles = useStyles();
  return (
    <Stack
      justifyContent={'space-between'}
      className={styles.UnitCard}
      sx={{
        width: ['250px', '400px', '450px', '450px', '450x'],
        height: ['179px', '190px', '200px', '200px', '220px'],
        paddingTop: ['24px', '26px', '28px', '30px', '32px'],
        paddingRight: '24px',
        paddingBottom: '16px',
        paddingLeft: '24px',
      }}
      // spacing={3}
    >
      <Stack
        alignItems={'center'}
        sx={{
          paddingLeft: ['8px', '8px', '14px', '14px', '14px'],
        }}
        direction={'row'}
        spacing={2.2}
        className={styles.UnitCard__Top}
      >
        <Stack
          sx={{ marginTop: '5px' }}
          alignSelf={'start'}
        >
          {props.icon}
        </Stack>
        <Stack
          spacing={1.4}
          className={styles.UnitCard__TitleBlock}
          // paddingRight={6}
        >
          <Typography
            fontWeight={600}
            className={styles.UnitCard__Title}
            sx={{
              fontSize: ['16px', '20px', '20px', '21px', '22px'],
            }}
          >
            <>Обслуживание <br/> {props.title}</>
          </Typography>
          <Link underline={'hover'}>
            <Stack
              spacing={1}
              alignItems={'center'}
              direction={'row'}
            >

              <Typography
                color={Color.Gray2}
                fontWeight={400}
                // fontSize={19}
                sx={{
                  fontSize: ['13px', '16px', '16px', '16px', '16px'],
                  cursor: 'pointer',
                }}
                onClick={props.onMoreClick}
              >
                Подробнее
              </Typography>

              <div style={{ cursor: 'pointer' }}>
                <ArrowDown/>
              </div>
            </Stack>
          </Link>
        </Stack>
      </Stack>
      <Stack
        className={styles.UnitCard__Bottom}
        spacing={2.7}
      >
        <Stack className={styles.UnitCard__Divider}><Divider/></Stack>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          // className={styles.UnitCard__Bottom}
        >
          <Typography
            fontWeight={'bold'}
            sx={{
              fontSize: ['18px', '22px', '24px', '26px', '28px'],
            }}
            // fontSize={28}
          >
            {props.price}
          </Typography>
          <button
            onClick={props.onButtonClick}
            className={styles.UnitCard__Button}
          >
            <Typography
              sx={{
                fontSize: ['16px', '16px', '17px', '18px', '18px'],
              }}
            >
              Заказать
            </Typography>
          </button>
        </Stack>
      </Stack>
    </Stack>
  );
};
