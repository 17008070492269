import React from 'react';

export const CoffeeMachineImage = () => (
  <svg
    width={'42'}
    height={'50'}
    viewBox={'0 0 42 50'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <path
      d={'M40 0H0V11.4514H6.13358V13.8323H8.64633V16.389H11.2469V13.8323H13.6894V11.4514H27.6648V36.6948H0V48H40V0Z'}
      fill={'url(#paint0_linear_1165_2914)'}
    />
    <path
      d={'M15 24H5V32C5 32 6 36 10 36C14 36 15 32 15 32V24Z'}
      stroke={'url(#paint1_linear_1165_2914)'}
      strokeWidth={'2'}
    />
    <path
      d={'M19.5 28.5C19.5 25 15 26 15 26V31C15 31 19.5 32.0001 19.5 28.5Z'}
      stroke={'url(#paint2_linear_1165_2914)'}
    />
    <defs>
      <linearGradient
        id={'paint0_linear_1165_2914'}
        x1={'-12.48'}
        y1={'-2.18719'}
        x2={'12.4805'}
        y2={'62.6243'}
        gradientUnits={'userSpaceOnUse'}
      >
        <stop stopColor={'#7851CF'}/>
        <stop offset={'0.166667'} stopColor={'#7C71E8'}/>
        <stop offset={'1'} stopColor={'#808EFF'}/>
      </linearGradient>
      <linearGradient
        id={'paint1_linear_1165_2914'}
        x1={'1.88'}
        y1={'23.4532'}
        x2={'8.12012'}
        y2={'39.6561'}
        gradientUnits={'userSpaceOnUse'}
      >
        <stop stopColor={'#7851CF'}/>
        <stop offset={'0.166667'} stopColor={'#7C71E8'}/>
        <stop offset={'1'} stopColor={'#808EFF'}/>
      </linearGradient>
      <linearGradient
        id={'paint2_linear_1165_2914'}
        x1={'13.596'}
        y1={'25.6279'}
        x2={'16.2821'}
        y2={'32.782'}
        gradientUnits={'userSpaceOnUse'}
      >
        <stop stopColor={'#7851CF'}/>
        <stop offset={'0.166667'} stopColor={'#7C71E8'}/>
        <stop offset={'1'} stopColor={'#808EFF'}/>
      </linearGradient>
    </defs>
  </svg>
);
