import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(
  { viewBox: '0 0 24 25', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
)`
  width: 24px;
  height: 25px;
`;

export const CustomDatePickerLeftArrowIcon = () => (
  <Svg>
    <path d={'M14.375 17.8896L9.375 12.8896L14.375 7.88965'} stroke={'#232D42'} strokeWidth={'2'} strokeLinecap={'round'} strokeLinejoin={'round'}/>
    <path d={'M22.375 12.3896L22.375 13.3896L24.375 13.3896L24.375 12.3896L22.375 12.3896ZM1.375 13.3896L1.375 12.3896L-0.625 12.3896L-0.625 13.3896L1.375 13.3896ZM1.375 12.3896C1.375 6.59066 6.07601 1.88965 11.875 1.88965L11.875 -0.110351C4.97144 -0.110351 -0.625 5.48609 -0.625 12.3896L1.375 12.3896ZM11.875 23.8896C6.07601 23.8896 1.375 19.1886 1.375 13.3896L-0.625 13.3896C-0.625 20.2932 4.97144 25.8896 11.875 25.8896L11.875 23.8896ZM22.375 13.3896C22.375 19.1886 17.674 23.8896 11.875 23.8896L11.875 25.8896C18.7786 25.8896 24.375 20.2932 24.375 13.3896L22.375 13.3896ZM24.375 12.3896C24.375 5.48609 18.7786 -0.110352 11.875 -0.110351L11.875 1.88965C17.674 1.88965 22.375 6.59066 22.375 12.3896L24.375 12.3896Z'} fill={'black'} fillOpacity={'0.08'}/>
  </Svg>
);
