import { Stack, Typography } from '@mui/material';
import React from 'react';
import { Color } from '~/ui';
import { BackIcon } from './back-icon';

type BackModalProps = {
  onClick: () => void;
};

export const BackModal = (props: BackModalProps) => (
  <Stack
    sx={{
      position: 'absolute',
      top: '28px',
      left: '26px',
      cursor: 'pointer',
    }}
    onClick={props.onClick}
    spacing={1.5}
    direction={'row'}
    alignItems={'center'}
  >
    <BackIcon/>
    <Typography
      color={Color.Gray3}
      fontSize={'1em'}
    >
      Назад
    </Typography>
  </Stack>
)
