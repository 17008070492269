import React from 'react';

export const DoneImage = () => (
  <svg
    width={'28'}
    height={'28'}
    viewBox={'0 0 28 28'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <circle cx={'14'} cy={'14.0001'} r={'13.5'} fill={'#E9E8FC'}/>
    <path
      /* eslint-disable-next-line max-len */
      d={'M20.2154 10.1319L19.8694 9.7859C19.4886 9.40478 18.8656 9.40478 18.4848 9.7859L12.6024 15.6683L10.5154 13.5817C10.1351 13.201 9.51156 13.201 9.13126 13.5821L8.78523 13.9277C8.40492 14.3084 8.40492 14.9315 8.78523 15.3122L11.9083 18.4378C12.289 18.8189 12.9121 18.8189 13.2928 18.4378L20.2154 11.5164C20.5949 11.1357 20.5949 10.5126 20.2154 10.1319Z'}
      fill={'#6156D5'}
    />
  </svg>

);
