import React from 'react';
import { createUseStyles } from 'react-jss';

type WorkerImageProps = {
  small?: boolean;
};

const useStyles = createUseStyles({
  'WorkerImage': {
    // width: '75%',
    // height: '80%',
  },
  '@media(max-width: 340px)': {

  },
});

export const WorkerImage = (props: WorkerImageProps) => {
  const styles = useStyles();
  return (
    <svg
      width={props.small ? '207' : '324'}
      height={props.small ? '241' : '374'}
      viewBox={'0 0 324 374'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <circle cx={'185.5'} cy={'138.5'} r={'138.5'} fill={'#7A79E8'} fillOpacity={'0.08'}/>
      <path
        /* eslint-disable-next-line max-len */
        d={'M61.2976 226.104C61.2976 226.104 73.9882 232.529 69.1369 239.961C64.2856 247.393 53.7101 233.032 53.7101 233.032L61.2976 226.104Z'}
        fill={'#F4A28C'}
      />
      <path
        opacity={'0.45'}
        /* eslint-disable-next-line max-len */
        d={'M221.541 353.975C221.586 343.27 172.244 334.386 111.332 334.13C50.4201 333.875 1.00498 342.346 0.960124 353.051C0.915269 363.756 50.2576 372.64 111.169 372.895C172.081 373.15 221.496 364.679 221.541 353.975Z'}
        fill={'#E6E6E6'}
      />
      <path
        /* eslint-disable-next-line max-len */
        d={'M90.3053 99.0405C90.6595 97.7577 90.669 96.4042 90.3326 95.1167C89.9963 93.8291 89.3261 92.6529 88.3897 91.7068C87.4532 90.7608 86.2837 90.0783 84.999 89.7282C83.7144 89.3781 82.3601 89.3728 81.0727 89.7129C56.9754 96.2943 35.8065 110.812 20.9938 130.916C18.3583 134.489 15.8403 137.978 13.4734 141.35C5.60155 152.547 2.40614 166.369 4.56563 179.882C6.72511 193.395 14.0694 205.534 25.0394 213.723L52.3846 234.174L63.1951 224.292L41.2551 203.339C38.4428 200.652 36.2062 197.423 34.6813 193.846C33.1563 190.269 32.3748 186.419 32.3843 182.531C32.3937 178.643 33.1938 174.798 34.7361 171.228C36.2783 167.659 38.5305 164.44 41.3558 161.767L84.4301 121.051L90.3053 99.0405Z'}
        fill={'#685DED'}
      />
      <path
        opacity={'0.46'}
        /* eslint-disable-next-line max-len */
        d={'M90.3053 99.0405C90.6595 97.7577 90.669 96.4042 90.3326 95.1167C89.9963 93.8291 89.3261 92.6529 88.3897 91.7068C87.4532 90.7608 86.2837 90.0783 84.999 89.7282C83.7144 89.3781 82.3601 89.3728 81.0727 89.7129C56.9754 96.2943 35.8065 110.812 20.9938 130.916C18.3583 134.489 15.8403 137.978 13.4734 141.35C5.60155 152.547 2.40614 166.369 4.56563 179.882C6.72511 193.395 14.0694 205.534 25.0394 213.723L52.3846 234.174L63.1951 224.292L41.2551 203.339C38.4428 200.652 36.2062 197.423 34.6813 193.846C33.1563 190.269 32.3748 186.419 32.3843 182.531C32.3937 178.643 33.1938 174.798 34.7361 171.228C36.2783 167.659 38.5305 164.44 41.3558 161.767L84.4301 121.051L90.3053 99.0405Z'}
        fill={'white'}
      />
      <path
        /* eslint-disable-next-line max-len */
        d={'M115.653 61.9814C113.466 66.22 111.862 70.7346 110.886 75.4025C110.803 75.9625 110.832 76.5334 110.971 77.0821C111.111 77.6308 111.358 78.1464 111.698 78.5989C112.039 79.0515 112.466 79.432 112.954 79.7184C113.443 80.0049 113.984 80.1915 114.545 80.2676C116.339 80.6254 118.198 80.4597 119.901 79.7904C121.604 79.1211 123.077 77.9764 124.147 76.4929L129.368 70.5205C130.29 69.2213 130.785 67.6678 130.785 66.0748C130.785 64.4819 130.29 62.9283 129.368 61.6291C126.212 56.5962 117.818 57.2337 115.653 61.9814Z'}
        fill={'#F4A28C'}
      />
      <path d={'M126.73 68.3228L127.973 96.3392L112.479 95.9198L117.8 75.7043L126.73 68.3228Z'} fill={'#F4A28C'}/>
      <path
        /* eslint-disable-next-line max-len */
        d={'M121.276 72.1984C121.276 72.1984 122.585 69.1786 124.785 70.353C126.984 71.5273 125.103 75.9059 121.813 75.0336L121.276 72.1984Z'}
        fill={'#F4A28C'}
      />
      <path
        /* eslint-disable-next-line max-len */
        d={'M113.418 66.9136L109.943 69.0442C109.749 69.1687 109.589 69.34 109.479 69.5422C109.368 69.7445 109.31 69.9713 109.31 70.2017C109.31 70.4322 109.368 70.659 109.479 70.8613C109.589 71.0635 109.749 71.2348 109.943 71.3593L112.73 73.1712L113.418 66.9136Z'}
        fill={'#F4A28C'}
      />
      <path
        opacity={'0.31'}
        /* eslint-disable-next-line max-len */
        d={'M116.542 80.3348C118.512 80.0719 120.362 79.2379 121.863 77.9358C121.863 77.9358 121.058 82.9687 114.88 87.3976L116.542 80.3348Z'}
        fill={'#CE8172'}
      />
      <path
        /* eslint-disable-next-line max-len */
        d={'M127.402 75.8721L133.412 65.6721C133.412 65.6721 138.968 63.9945 136.551 59.5823C134.134 55.1702 130.525 57.1665 129.148 51.4291C129.035 50.3989 128.607 49.4286 127.922 48.6508C127.236 47.873 126.328 47.3256 125.319 47.0833C124.311 46.841 123.253 46.9157 122.288 47.2972C121.324 47.6786 120.501 48.3483 119.932 49.2146C117.901 52.184 110.683 48.9797 110.817 54.2475C110.951 59.5152 117.683 61.6626 119.597 62.2665C127.973 64.8836 117.515 74.0435 127.402 75.8721Z'}
        fill={'#24285B'}
      />
      <path
        /* eslint-disable-next-line max-len */
        d={'M81.7747 344.779C81.7747 344.779 79.4918 351.104 73.7843 351.591C68.0769 352.077 66.5661 357.647 71.7028 359.106C76.8395 360.566 90.168 353.956 90.168 353.956L89.7987 345.719L81.7747 344.779Z'}
        fill={'#685DED'}
      />
      <path
        /* eslint-disable-next-line max-len */
        d={'M159.163 344.779C159.163 344.779 161.446 351.104 167.154 351.591C172.861 352.077 174.372 357.647 169.235 359.106C164.098 360.566 150.77 353.956 150.77 353.956L151.139 345.719L159.163 344.779Z'}
        fill={'#685DED'}
      />
      <path
        /* eslint-disable-next-line max-len */
        d={'M91.1433 88.1355C116.591 88.6066 141.709 93.978 165.122 103.956C168.711 105.475 171.951 107.712 174.642 110.531C177.332 113.35 179.417 116.689 180.766 120.344C182.116 123.999 182.702 127.891 182.488 131.781C182.275 135.67 181.266 139.475 179.524 142.96L139.237 223.202L64.6038 218.789C64.6038 218.789 51.0235 173.695 53.743 122.678C54.2926 113.203 58.4967 104.309 65.4715 97.8674C72.4462 91.4256 81.6484 87.9372 91.1433 88.1355Z'}
        fill={'#685DED'}
      />
      <path
        d={'M139.254 223.185L161.547 345.903H150.401L122.451 279.285C112.55 255.69 95.099 236.042 72.8301 223.42L64.6215 218.772L139.254 223.185Z'}
        fill={'#24285B'}
      />
      <path
        d={'M64.6212 218.789L76.1368 345.92H90.9593C90.9593 345.92 87.5013 250.295 114.242 229.895L64.6212 218.789Z'}
        fill={'#24285B'}
      />
      <path
        opacity={'0.08'}
        /* eslint-disable-next-line max-len */
        d={'M144.188 109.844C144.188 109.844 125.135 122.091 131.128 159.754C132.88 170.764 132.171 182.025 129.051 192.728C125.93 203.432 120.477 213.312 113.082 221.658L139.252 223.202C139.252 223.202 156.039 188.257 160.504 180.842C164.969 173.426 144.188 109.844 144.188 109.844Z'}
        fill={'black'}
      />
      <path
        /* eslint-disable-next-line max-len */
        d={'M140.33 121.252C140.246 104.19 161.582 96.3223 172.997 109.005C182.956 119.794 189.305 133.415 191.16 147.977C195.709 185.438 190.706 267.223 190.706 267.223H177.143C177.143 267.223 167.977 185.488 147.968 147.977C142.395 137.525 140.364 128.633 140.33 121.252Z'}
        fill={'#685DED'}
      />
      <path
        opacity={'0.46'}
        /* eslint-disable-next-line max-len */
        d={'M140.33 121.252C140.246 104.19 161.582 96.3223 172.997 109.005C182.956 119.794 189.305 133.415 191.16 147.977C195.709 185.438 190.706 267.223 190.706 267.223H177.143C177.143 267.223 167.977 185.488 147.968 147.977C142.395 137.525 140.364 128.633 140.33 121.252Z'}
        fill={'white'}
      />
      <path
        /* eslint-disable-next-line max-len */
        d={'M195.539 290.844V285.056C195.539 282.956 194.704 280.942 193.218 279.457C191.732 277.972 189.717 277.137 187.616 277.137H180.112C178.011 277.137 175.996 277.972 174.51 279.457C173.024 280.942 172.189 282.956 172.189 285.056V291.263'}
        stroke={'#24285B'}
        strokeWidth={'2.03'}
        strokeMiterlimit={'10'}
      />
      <path d={'M137.206 305.439H228.793V325.386H137.206V305.439Z'} fill={'#FFD200'}/>
      <path opacity={'0.08'} d={'M137.206 305.439H228.793V325.386H137.206V305.439Z'} fill={'black'}/>
      <path d={'M148.386 288.562L137.206 305.439H228.793L217.596 288.562H148.386Z'} fill={'#FFD200'}/>
      <path
        /* eslint-disable-next-line max-len */
        d={'M188.356 267.222C188.356 267.222 192.419 280.727 187.064 280.727C181.709 280.727 175.313 283.294 179.359 267.222H188.356Z'}
        fill={'#F4A28C'}
      />
      <path
        /* eslint-disable-next-line max-len */
        d={'M138.144 59.4648L109.121 55.5392C108.894 55.5057 108.684 55.4052 108.515 55.2507C108.347 55.0962 108.229 54.8947 108.177 54.6724C108.124 54.4502 108.14 54.2173 108.221 54.0039C108.303 53.7905 108.446 53.6065 108.634 53.4757L109.876 52.6201C111.296 51.6675 112.298 50.2095 112.679 48.5434C113.77 44.0306 117.01 34.9379 125.706 34.5688C136.818 34.0823 147.78 42.4033 141.132 57.7704C140.888 58.3405 140.464 58.815 139.924 59.121C139.385 59.4271 138.759 59.5478 138.144 59.4648Z'}
        fill={'#FFD200'}
      />
      <path
        opacity={'0.31'}
        /* eslint-disable-next-line max-len */
        d={'M140.666 49.3318C140.704 47.3622 140.28 45.411 139.429 43.6341C138.578 41.8573 137.323 40.3039 135.764 39.0982C135.602 38.9878 135.408 38.9329 135.212 38.9419C135.016 38.9508 134.828 39.0232 134.677 39.148C134.525 39.2729 134.418 39.4435 134.372 39.6342C134.326 39.8248 134.343 40.0253 134.421 40.2055C135.821 43.087 136.599 46.2308 136.704 49.4325C136.719 49.8927 136.895 50.333 137.202 50.6765C137.509 51.02 137.927 51.2447 138.383 51.3114C138.667 51.3553 138.957 51.3367 139.234 51.2571C139.51 51.1775 139.766 51.0386 139.983 50.8502C140.2 50.6618 140.374 50.4284 140.492 50.1662C140.61 49.904 140.669 49.6193 140.666 49.3318Z'}
        fill={'white'}
      />
      <path
        /* eslint-disable-next-line max-len */
        d={'M139.823 226.104C139.823 226.104 130.809 178.224 130.809 151.768C130.809 112.662 144.977 95.9697 144.977 95.9697L139.101 94.0907C139.101 94.0907 126.814 114.105 106.619 109.844C87.8185 105.901 92.5858 87.6654 92.6698 87.397H87.2309C87.2309 87.397 69.7058 101.724 65.5931 124.791C58.6099 163.88 64.6698 218.739 64.6698 218.739L139.823 226.104Z'}
        fill={'#24285B'}
      />
      <path d={'M65.5427 218.789H145.598V228.922H65.5427V218.789Z'} fill={'#C9C9C9'}/>
      <path
        /* eslint-disable-next-line max-len */
        d={'M136.266 213.522H145.415C147.371 213.522 148.957 215.107 148.957 217.062V233.737C148.957 235.692 147.371 237.277 145.415 237.277H136.266C134.31 237.277 132.725 235.692 132.725 233.737V217.062C132.725 215.107 134.31 213.522 136.266 213.522Z'}
        fill={'#FFD200'}
      />
      <path
        opacity={'0.46'}
        /* eslint-disable-next-line max-len */
        d={'M136.266 213.522H145.415C147.371 213.522 148.957 215.107 148.957 217.062V221.407C148.957 223.362 147.371 224.946 145.415 224.946H136.266C134.31 224.946 132.725 223.362 132.725 221.407V217.062C132.725 215.107 134.31 213.522 136.266 213.522Z'}
        fill={'white'}
      />
      <path
        /* eslint-disable-next-line max-len */
        d={'M140.831 221.424C139.58 221.424 138.565 220.41 138.565 219.159C138.565 217.908 139.58 216.894 140.831 216.894C142.083 216.894 143.098 217.908 143.098 219.159C143.098 220.41 142.083 221.424 140.831 221.424Z'}
        fill={'#24285B'}
      />
      <path
        /* eslint-disable-next-line max-len */
        d={'M117.114 213.522H126.263C128.219 213.522 129.805 215.107 129.805 217.062V233.737C129.805 235.692 128.219 237.277 126.263 237.277H117.114C115.158 237.277 113.572 235.692 113.572 233.737V217.062C113.572 215.107 115.158 213.522 117.114 213.522Z'}
        fill={'#FFD200'}
      />
      <path
        opacity={'0.46'}
        /* eslint-disable-next-line max-len */
        d={'M117.114 213.522H126.263C128.219 213.522 129.805 215.107 129.805 217.062V221.407C129.805 223.362 128.219 224.946 126.263 224.946H117.114C115.158 224.946 113.572 223.362 113.572 221.407V217.062C113.572 215.107 115.158 213.522 117.114 213.522Z'}
        fill={'white'}
      />
      <path
        /* eslint-disable-next-line max-len */
        d={'M121.695 221.424C120.443 221.424 119.429 220.41 119.429 219.159C119.429 217.908 120.443 216.894 121.695 216.894C122.946 216.894 123.961 217.908 123.961 219.159C123.961 220.41 122.946 221.424 121.695 221.424Z'}
        fill={'#24285B'}
      />
      <path
        /* eslint-disable-next-line max-len */
        d={'M66.0321 213.522H75.1807C77.1369 213.522 78.7227 215.107 78.7227 217.062V233.737C78.7227 235.692 77.1369 237.277 75.1807 237.277H66.0321C64.0759 237.277 62.4901 235.692 62.4901 233.737V217.062C62.4901 215.107 64.0759 213.522 66.0321 213.522Z'}
        fill={'#FFD200'}
      />
      <path
        opacity={'0.46'}
        /* eslint-disable-next-line max-len */
        d={'M66.0321 213.522H75.1807C77.1369 213.522 78.7227 215.107 78.7227 217.062V221.407C78.7227 223.362 77.1369 224.946 75.1807 224.946H66.0321C64.0759 224.946 62.4901 223.362 62.4901 221.407V217.062C62.4901 215.107 64.0759 213.522 66.0321 213.522Z'}
        fill={'white'}
      />
      <path
        /* eslint-disable-next-line max-len */
        d={'M70.6127 221.424C69.3612 221.424 68.3465 220.41 68.3465 219.159C68.3465 217.908 69.3612 216.894 70.6127 216.894C71.8643 216.894 72.8789 217.908 72.8789 219.159C72.8789 220.41 71.8643 221.424 70.6127 221.424Z'}
        fill={'#24285B'}
      />
      <path
        /* eslint-disable-next-line max-len */
        d={'M33.8008 351.507C33.8008 351.507 29.8727 344.796 24.0981 345.619C23.3646 345.645 22.6442 345.821 21.9811 346.136C21.3181 346.45 20.7263 346.897 20.2422 347.448C19.7581 348 19.3917 348.644 19.1657 349.342C18.9397 350.04 18.8588 350.777 18.9278 351.507H33.8008Z'}
        fill={'#C9C9C9'}
      />
      <path
        opacity={'0.2'}
        /* eslint-disable-next-line max-len */
        d={'M82.544 145.473C82.544 145.473 119.915 147.875 131.198 145.473L132.723 176.099C132.723 176.099 95.1237 181.359 84.1383 177.362L82.544 145.473Z'}
        fill={'white'}
      />
    </svg>
  );
};
