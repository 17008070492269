import React from 'react';

export const IconCheckboxNotChecked: React.FC = () => (
  <svg
    width={'24'}
    height={'24'}
    viewBox={'0 0 24 24'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <rect
      x={'2'}
      y={'2'}
      width={'20'}
      height={'20'}
      rx={'4'}
      fill={'#E1E4FC'}
      stroke={'#E1E4FC'}
      strokeWidth={'1.66'}
      strokeLinejoin={'bevel'}
    />
  </svg>
);
