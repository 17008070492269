import React from 'react';
import { Stack, Typography } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { WorkerImage } from './worker-image';

const useStyles = createUseStyles({
  Welcome: {
    'marginTop': '5vh',
    'width': 1040,
    'justifyContent': 'space-between',
    'alignSelf': 'center',
    // 'minHeight': 300,
    '@media(max-width: 1032px)': {
      width: 'auto',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '@media(max-width: 913px)': {
      // paddingTop: '30px',
      justifyContent: 'flex-start',
      overflow: 'hidden',
    },
  },
  Welcome__Text: {
    'display': 'flex',
    '@media(max-width: 913px)': {
      marginTop: '2vh',
      padding: '1px',
      alignSelf: 'flex-start',
      width: '80%',
    },
  },
  Welcome__Worker: {
    '@media(max-width: 913px)': {
      // paddingTop: '30px',
      marginLeft: '75%',
      marginTop: '-20%',
    },
    '@media(max-width: 500px)': {
      // paddingTop: '30px',
      marginLeft: '65%',
      marginTop: '-20%',
    },
  },
});

type WelcomeProps = {
  small: boolean;
};

export const Welcome = (props: WelcomeProps) => {
  const styles = useStyles();
  return (
    <Stack
      className={styles.Welcome}
      alignItems={'center'}
      flexWrap={'wrap'}
      direction={'row'}
    >
      <Stack
        alignSelf={'center'}
        spacing={2.5}
        padding={1}
        className={styles.Welcome__Text}
      >
        <Typography
          fontWeight={'bold'}
          sx={{
            fontSize: ['30px', '34px', '38px', '42px', '44px'],
          }}
        >
          Уход за техникой - это просто!
        </Typography>
        <Typography
          sx={{
            fontSize: ['22px', '22px', '24px', '26pxm', '32px'],
          }}
        >
          Для каждого прибора мы разработали свой <br/> рецепт ухода с индивидуальным набором <br/> чистящих средств
        </Typography>
      </Stack>
      <Stack
        className={styles.Welcome__Worker}
      >
        <WorkerImage small={props.small}/>
      </Stack>
    </Stack>
  );
};
