import React from 'react';

export const ArrowDown = () => (
  <svg
    width={'16'}
    height={'16'}
    viewBox={'0 0 16 16'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <path
      d={'M5.66797 5.33329L10.3346 9.99996L5.66797 14.6666'}
      stroke={'#B3AFAF'}
      strokeWidth={'1.5'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
    />
  </svg>

);
