import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { mediaBreakpoints } from '../common';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
`;
const Circle = styled.div`
  width: 50px;
  height: 50px;
  background: #FFFFFF;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    width: 35px;
    height: 35px;
  }
`;
const Text = styled.div`
  padding-left: 11px;
  font-weight: 400;
  font-size: 22px;
  line-height: 52px;
  text-align: center;
  color: rgba(27, 32, 64, 0.7);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    font-size: 18px;
    display: none;
  }
`;

type GoBackButtonProps = {
  link?: string;
};

export const GoBackButton: React.FC<GoBackButtonProps> = (props) => {

  const handleGoAway = () => {
    window.location.href = props.link;
  };

  return (
    <Link
      to={'/'}
      style={{ textDecoration: 'none' }}
      onClick={(e) => {
        if (props.link) {
          handleGoAway();
          e.preventDefault();
        }
      }}
    >
      <Wrapper>
        <Circle>
          <svg
            width={'20'}
            height={'20'}
            viewBox={'0 0 20 20'}
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
          >
            <path
              d={'M12 5L7 10L12 15'}
              stroke={'#1B2040'}
              strokeWidth={'2'}
              strokeLinejoin={'bevel'}
            />
          </svg>
        </Circle>
        <Text>
          Назад
        </Text>
      </Wrapper>
    </Link>
  );
};
