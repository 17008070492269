import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { useMainService } from '~/main';

export const Order: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const mainService = useMainService();

  useEffect(() => {
    mainService.checkOrder(params.id)
      .then((result) => {
        console.log('result >>>', result);
        if (result.paid) {
          navigate('/success');
        } else {
          navigate('/fail');
        }
      })
      .catch((error) => {
        console.log('error >>>', error);
        navigate('/fail');
      });
  }, []);

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress color={'inherit'}/>
    </Box>
  );
};
