import { Container } from '@mui/material';
import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';
import styled from 'styled-components';
import { registerDependency } from '~/1st-di';
import { LocalStorageRepository } from '~/1st-core';
import { NotifyService } from '~/1st-react-notify';
import { App, AppSessionService } from '~/app';
import {
  Main,
  OrderService,
  MainService,
  Finish, Order,
} from '~/main';
import { ProcessingComponent } from '~/main/components/processing-component';
import { RoleService } from '~/role';
import { SessionPanelService } from '~/session-panel';
import { mediaBreakpoints, Header, Footer } from '~/ui';
import { Oferta } from '~/ui/components/oferta';
import { UserAgreement } from '~/ui/components/user-agreement';
import { UserService } from '~/user';

registerDependency('STORAGE_REPOSITORY', () => new LocalStorageRepository());
registerDependency('NOTIFY_SERVICE', () => new NotifyService());
registerDependency('SESSION_SERVICE', () => new AppSessionService());

registerDependency('ROLE_SERVICE', () => new RoleService());
registerDependency('MAIN_SERVICE', () => new MainService());
registerDependency('ORDER_SERVICE', () => new OrderService());
registerDependency('SESSION_PANEL_SERVICE', () => new SessionPanelService());
registerDependency('USER_SERVICE', () => new UserService());

const container = document.getElementById('root');
const root = createRoot(container);

const ContainerLocal = styled(Container)`
  max-width: 1000rem;
  margin-left: 50px;
  padding: 100px 0;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    max-width: calc(100% - 32px);
    margin-left: 10px;
    padding: 80px 0;
  }
`;

root.render((
  <App>
    <BrowserRouter>
      <Routes>
        <Route path={'/'} element={<Main/>}/>
        <Route path={'*'} element={<>404</>}/>
        <Route path={'/order/:id'} element={<Order/>}/>
        <Route path={'payment'} element={<Finish/>}/>
        <Route
          path={'/about/oferta'}
          element={(
            <>
              <ContainerLocal>
                <Header showGoToHomeButton alignToLeft/>
                <Oferta/>
              </ContainerLocal>
              <Footer/>
            </>
          )}
        />
        <Route
          path={'/about/personal'}
          element={(
            <>
              <ContainerLocal>
                <Header showGoToHomeButton alignToLeft/>
                <UserAgreement/>
              </ContainerLocal>
              <Footer/>
            </>
          )}
        />

        <Route
          path={'/success'}
          element={
            <ProcessingComponent
              isSuccess
              title={'Вы успешно активировали сертификат!'}
              subtitleText={'Наш менеджер скоро свяжется с вами для уточнения деталей.'}
            />
          }
        />
        <Route
          path={'/fail'}
          element={
            <ProcessingComponent
              isSuccess={false}
              title={'Похоже возникла проблема с загрузкой страницы'}
              subtitleText={'Попробуйте вернуться на Главную страницу и отправить форму заново'}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  </App>
));
