import React from 'react';
import styled from 'styled-components';
import { Color, mediaBreakpoints } from '../common';
import loadApple from '../images/load-in-apple.svg';
import loadGoogle from '../images/load-in-google-play.svg';

const Wrapper = styled.div`
  display: flex;
  gap: 19em;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    gap: 15em;
  }
  font-size: ${1 / 14.4}vw; // 1440 / 100, 1440 - figma design screen wide
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    font-size: ${1 / 3.75}vw; // 375 / 100, 375 - figma design mobile screen wide
  }
  @media screen and (min-width: ${mediaBreakpoints.desktopToLarges}px) {
    font-size: 1.38889px; // {1 / 14.4}vw - pixel value on 1920 screen wide
  }
`;

const AppsLink = styled.a`
  width: 181em;
  height: 53em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1em solid #A6A6A6;
  background: ${Color.TextPrimary};
  border-radius: 10em;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    width: 135em;
    height: 39em;
  }

  img {
    width: 100%;
  }
`;

const LinkApple = styled(AppsLink)`
  img {
    width: 80%;
  }
`;

const LinkGooglePlay = styled(AppsLink)`
  img {
    width: 90%;
  }
`;
type LinksToMobileAppsProps = {
  appleLink?: string;
  googleLink?: string;
};

export const LinksToMobileApps: React.FC<LinksToMobileAppsProps> = (props) => (
  <Wrapper>
    <LinkApple
      href={props.appleLink ?? 'https://apps.apple.com/ru/app/майтех-ваш-эксперт-по-технике/id1610305014'}
      target={'_blank'}
    >
      <img
        src={loadApple}
        alt={'загрузите в эппл стор'}
      />
    </LinkApple>
    <LinkGooglePlay
      href={props.googleLink ?? 'https://play.google.com/store/apps/details?id=online.u3.mytech'}
      target={'_blank'}
    >
      <img
        src={loadGoogle}
        alt={'загрузите в гугл плей'}
      />
    </LinkGooglePlay>
  </Wrapper>
);
