import { GlobalStyles } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { Color, Fonts } from '~/ui';

export const lightTheme = createTheme({
  shape: {
    borderRadius: 20,
  },
  palette: {
    mode: 'light',
  },
  typography: {
    fontFamily: 'Commissioner, sans-serif',
    button: {
      textTransform: 'none',
    },
  },
});

export const UiThemeProvider: React.FC<React.PropsWithChildren> = (props) => (
  <ThemeProvider theme={lightTheme}>
    <GlobalStyles
      styles={{
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        svg: { display: 'block' },
        html: {
          fontFamily: Fonts.Comissioner,
        },
        body: {
          backgroundColor: Color.Gray1,
          color: Color.TextPrimary,
          margin: 0,
          padding: 0,
        },
        input: {
          '&:-webkit-autofill': {
            borderRadius: 'inherit',
          },
        },
      }}
    />
    {props.children}
  </ThemeProvider>
);
