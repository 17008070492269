import { useApi } from '~/app';
import { OrderModalData } from '../components';
import { PublicPayment } from '~/api';

export class MainService {
  async sendOrder(data: OrderModalData & { id: string; }): Promise<string> {
    const api = useApi();

    const sentData = {
      itemId: data.id,
      name: data.name,
      phone: `+${data.phoneNumber.replace(/[^0-9]/g, '')}`,
      address: data.address,
      date: data.date,
    };

    console.log('data >>>', data);
    console.log('sentData >>>', sentData);

    return api.payment.send({
      data: sentData,
    });
  }

  async checkOrder(orderId: string): Promise<PublicPayment> {
    const api = useApi();
    console.log('checkOrder 1', orderId);

    return api.payment.check({
      query: {
        paymentId: orderId,
      },
    });
  }
}
