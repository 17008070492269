import React from 'react';
import { NotifyProvider } from '~/1st-react-notify';
import { UiThemeProvider } from '~/ui';

export const App: React.FC<React.PropsWithChildren> = (props) => {
  return (
    <UiThemeProvider>
      <NotifyProvider>
        {props.children}
      </NotifyProvider>
    </UiThemeProvider>
  );
};
