import { DialogTitle } from '@mui/material';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { CloseImage } from './close-image';

const useStyles = createUseStyles({
  DialogTitleWrapper__ModalClose: {
    position: 'absolute',
    top: 36,
    right: 36,
    cursor: 'pointer',
  },
});

type DialogTitleWrapperProps = {
  id: string;
  close: () => void;
  children: React.ReactNode;
};

export const DialogTitleWrapper = (props: DialogTitleWrapperProps) => {
  const styles = useStyles();
  return (
    <DialogTitle id={props.id} sx={{ padding: 0 }}>
      <div
        onClick={props.close}
        className={styles.DialogTitleWrapper__ModalClose}
      >
        <CloseImage/>
      </div>
      {props.children}
    </DialogTitle>
  );
};
