import React from 'react';
import { createUseStyles } from 'react-jss';

export type TypoHeight = 's' | 'm' | 'l' | 'xl';

export type TypoSize = 'base' | 'b1' | 'b2' | 'b3' | 'b4' | 'b5' | 'h1' | 'h2' | 'h2and5' | 'h3' | 'h4';

export type TypoWeight = 'bold' | 'medium' | 'regular' | 'thin';

export type TypoProps = {
  height?: TypoHeight;
  size?: TypoSize;
  tag?: string;
  weight?: TypoWeight;
  color?: string;
  children: React.ReactNode;
};

const height2px: Record<TypoHeight, string> = {
  s: '120%',
  m: '130%',
  l: '150%',
  xl: '175%',
};

const size2px: Record<TypoSize, number> = {
  base: 14,
  b1: 23,
  b2: 19,
  b3: 16,
  b4: 14,
  b5: 12,
  h1: 64,
  h2: 40,
  h2and5: 44,
  h3: 33,
  h4: 28,
};

const weight2px: Record<TypoWeight, number> = {
  bold: 700,
  medium: 500,
  regular: 400,
  thin: 100,
};

const useStyles = createUseStyles({
  Typo: (props: Required<TypoProps>) => ({
    lineHeight: height2px[props.height],
    fontSize: size2px[props.size],
    fontWeight: weight2px[props.weight],
    color: props.color,
  }),
});

export const Typo: React.FC<TypoProps> = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const {
    height = 'm',
    size = 'base',
    tag = 'span',
    weight = 'regular',
    color,
  } = props;

  const styles = useStyles({
    ...props,
    height,
    size,
    tag,
    weight,
    color,
  });

  return React.createElement(tag, {
    className: styles.Typo,
  }, props.children);
};
