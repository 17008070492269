import { BehaviorSubject } from 'rxjs';

export class OrderService {
  orderData$: BehaviorSubject<{ title: string; price: string; }>;

  constructor() {
    this.orderData$ = new BehaviorSubject(null);
  }

  setOrderData(title: string, price: string) {
    this.orderData$.next({
      title,
      price,
    });
  }
}
