import { GlobalStyles } from '@mui/material';
import {
  createTheme,
  ThemeProvider,
} from '@mui/material/styles';
import React from 'react';
import { Color, Fonts } from '~/ui';

export const lightTheme = createTheme({
  shape: {
    borderRadius: 20,
  },
  palette: {
    mode: 'light',
  },
  typography: {
    fontFamily: 'Commissioner, sans-serif',
    button: {
      textTransform: 'none',
    },
  },
});

export const UiThemeProvider: React.FC<React.PropsWithChildren> = (props) => (
  <ThemeProvider theme={lightTheme}>
    <GlobalStyles
      styles={{
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        // svg: { display: 'block' },
        html: {
          // fontSize: '0.1vw',
          // fontSize: `${1 / 14.4}vw`,
          fontFamily: Fonts.Comissioner,
          // [`@media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px)`]: {
          //   fontSize: `${1 / 3.75}vw`,
          // },
          // [`@media screen and (min-width: ${mediaBreakpoints.desktopToLarges}px)`]: {
          //   fontSize: '1.38889px',
          // },
        },
        body: {
          backgroundColor: Color.Gray1,
          color: Color.TextPrimary,
          margin: 0,
          padding: 0,
          // fontSize: '26rem',
          // todo remove
          // overflowX: 'hidden',
        },
      }}
    />
    {props.children}
  </ThemeProvider>
);
