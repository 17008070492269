import React from 'react';

export const CloseIconCircle = () => (
  <svg
    width={'36'}
    height={'36'}
    viewBox={'0 0 36 36'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <rect
      width={'36'}
      height={'36'}
      rx={'18'}
      fill={'white'}
    />
    <path
      d={'M24 12L12 24'}
      stroke={'#1B2040'}
      strokeWidth={'2'}
      strokeLinejoin={'bevel'}
    />
    <path
      d={'M24 24L12 12'}
      stroke={'#1B2040'}
      strokeWidth={'2'}
      strokeLinejoin={'bevel'}
    />
  </svg>
);
