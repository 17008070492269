import { mapOrVoid } from '~/1st-api';

export type Payment = {
  id?: string;
  yooId?: string;
  yooUrl?: string;
  paid?: boolean;
  name?: string;
  phone?: string;
  address?: string;
  date?: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type PaymentRaw = {
  id?: string;
  yoo_id?: string;
  yoo_url?: string;
  paid?: boolean;
  name?: string;
  phone?: string;
  address?: string;
  date?: string;
  created_at?: string;
  updated_at?: string;
};

export function mapPayment(data: Payment): PaymentRaw {
  return {
    id: mapOrVoid(data.id, String),
    yoo_id: mapOrVoid(data.yooId, String),
    yoo_url: mapOrVoid(data.yooUrl, String),
    paid: mapOrVoid(data.paid, Boolean),
    name: mapOrVoid(data.name, String),
    phone: mapOrVoid(data.phone, String),
    address: mapOrVoid(data.address, String),
    date: mapOrVoid(data.date, String),
    created_at: data.createdAt?.toISOString(),
    updated_at: data.updatedAt?.toISOString(),
  };
}

export function mapPaymentRaw(dataRaw: PaymentRaw): Payment {
  return {
    id: mapOrVoid(dataRaw.id, String),
    yooId: mapOrVoid(dataRaw.yoo_id, String),
    yooUrl: mapOrVoid(dataRaw.yoo_url, String),
    paid: mapOrVoid(dataRaw.paid, Boolean),
    name: mapOrVoid(dataRaw.name, String),
    phone: mapOrVoid(dataRaw.phone, String),
    address: mapOrVoid(dataRaw.address, String),
    date: mapOrVoid(dataRaw.date, String),
    createdAt: mapOrVoid(dataRaw.created_at, (date) => new Date(date)),
    updatedAt: mapOrVoid(dataRaw.updated_at, (date) => new Date(date)),
  };
}
