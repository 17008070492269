import React from 'react';
import { Typography, Stack, Link } from '@mui/material';
import { ProcessingSuccessIcon } from './processing-success-icon';
import { ProcessingErrorIcon } from './processing-error-icon';
import { Color } from '../common';

export interface ProcessingProps {
  isSuccess: boolean;
  errorText?: string;
  title: string;
  subtitleText: string;
}

//
// const LinkOld = styled.a`
//   margin-left: 5px;
//   margin-right: 5px;
//   text-decoration: none;
//   color: #8280EB
// `;

export const Processing: React.FC<ProcessingProps> = (props) => {
  if (props.isSuccess) {
    return (
      <Stack spacing={5} alignItems={'center'}>
        <ProcessingSuccessIcon/>
        <Stack spacing={5}>
          <Typography
            sx={{
              fontSize: ['24px', '24px', '24px', '32px', '32px'],
            }}
            textAlign={'center'}
          >
            {props.title}
          </Typography>
          <Stack spacing={2.5}>
            <Typography
              sx={{
                fontSize: ['18px', '18px', '20px', '24px', '24px'],
                color: Color.Gray3,
              }}
              textAlign={'center'}
            >
              {props.subtitleText}
            </Typography>
            <Typography
              sx={{
                fontSize: ['18px', '18px', '20px', '24px', '24px'],
                color: Color.Gray3,
              }}
              textAlign={'center'}
            >
              Остались вопросы? Свяжитесь с нами
              по телефону 8 (800) 770-71-08
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack spacing={5} alignItems={'center'}>
      <ProcessingErrorIcon/>
      <Stack spacing={5}>
        <Typography
          sx={{
            fontSize: ['24px', '24px', '24px', '32px', '32px'],
          }}
          textAlign={'center'}
        >
          {props?.errorText}
        </Typography>
        <Stack spacing={2.5}>
          <Typography
            sx={{
              fontSize: ['18px', '18px', '20px', '24px', '24px'],
              color: Color.Gray3,
            }}
            textAlign={'center'}
            fontWeight={300}
          >
            Попробуйте вернуться
            на
            <Link
              underline={'hover'}
              sx={{
                marginLeft: 0.5,
                marginRight: 1,
                textDecoration: 'none',
                color: '#8280EB',
              }}
              href={'/'}
            >
              Главную страницу
            </Link>
            и отправить форму заново
          </Typography>
          <Typography
            sx={{
              fontSize: ['18px', '18px', '20px', '24px', '24px'],
              color: Color.Gray3,
            }}
            textAlign={'center'}
            fontWeight={300}
          >
            Остались вопросы? Свяжитесь с нами
            по телефону 8 (800) 770-71-08
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
