import React from 'react';

export const WashingMachineImage = () => (
  <svg
    width={'43'}
    height={'53'}
    viewBox={'0 0 43 53'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <path
      /* eslint-disable-next-line max-len */
      d={'M24.943 34.2818C23.0281 37.3921 19.2516 36.8459 18.0484 34.3908C14.8742 37.8299 12.2852 34.2779 12.2852 34.2779C12.8664 38.8662 16.7914 42.4112 21.5508 42.4112C26.3867 42.4112 30.3633 38.7485 30.8406 34.0511C30.8406 34.0511 26.5844 36.5187 24.943 34.2818Z'}
      fill={'url(#paint0_linear_1087_9489)'}
    />
    <path
      /* eslint-disable-next-line max-len */
      d={'M2 16.2449V50.5546C2 51.2761 2.61094 51.8589 3.36797 51.8589H39.8695C40.625 51.8589 41.2367 51.2761 41.2367 50.5546V16.2449H2ZM21.5641 44.6886C15.4539 44.6886 10.5008 39.7497 10.5008 33.6561C10.5008 27.5641 15.4531 22.6236 21.5641 22.6236C27.6734 22.6236 32.6266 27.5633 32.6266 33.6561C32.6266 39.7497 27.6734 44.6886 21.5641 44.6886Z'}
      fill={'url(#paint1_linear_1087_9489)'}
    />
    <path
      /* eslint-disable-next-line max-len */
      d={'M39.8695 2H3.36797C2.61094 2 2 2.63032 2 3.40867V13.7392H41.2367V3.40867C41.2367 2.63032 40.625 2 39.8695 2ZM9.22031 9.82636C8.13672 9.82636 7.25859 8.94984 7.25859 7.86996C7.25859 6.78853 8.13672 5.91357 9.22031 5.91357C10.3039 5.91357 11.182 6.78853 11.182 7.86996C11.1828 8.94984 10.3047 9.82636 9.22031 9.82636ZM15.6984 9.82636C14.6156 9.82636 13.7367 8.94984 13.7367 7.86996C13.7367 6.78853 14.6156 5.91357 15.6984 5.91357C16.7812 5.91357 17.6602 6.78853 17.6602 7.86996C17.6609 8.94984 16.782 9.82636 15.6984 9.82636Z'}
      fill={'url(#paint2_linear_1087_9489)'}
    />
    <defs>
      <linearGradient
        id={'paint0_linear_1087_9489'}
        x1={'6.49585'}
        y1={'33.6702'}
        x2={'8.33177'}
        y2={'46.3671'}
        gradientUnits={'userSpaceOnUse'}
      >
        <stop stopColor={'#7851CF'}/>
        <stop offset={'0.166667'} stopColor={'#7C71E8'}/>
        <stop offset={'1'} stopColor={'#808EFF'}/>
      </linearGradient>
      <linearGradient
        id={'paint1_linear_1087_9489'}
        x1={'-10.2419'}
        y1={'14.6221'}
        x2={'4.5857'}
        y2={'65.5225'}
        gradientUnits={'userSpaceOnUse'}
      >
        <stop stopColor={'#7851CF'}/>
        <stop offset={'0.166667'} stopColor={'#7C71E8'}/>
        <stop offset={'1'} stopColor={'#808EFF'}/>
      </linearGradient>
      <linearGradient
        id={'paint2_linear_1087_9489'}
        x1={'-10.2419'}
        y1={'1.46509'}
        x2={'-8.51009'}
        y2={'19.5004'}
        gradientUnits={'userSpaceOnUse'}
      >
        <stop stopColor={'#7851CF'}/>
        <stop offset={'0.166667'} stopColor={'#7C71E8'}/>
        <stop offset={'1'} stopColor={'#808EFF'}/>
      </linearGradient>
    </defs>
  </svg>
);
