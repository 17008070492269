import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(
  { viewBox: '0 0 20 20', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
)`
  width: 20px;
  height: 20px;
`;

export const InfoFormIcon = () => (
  <Svg>
    <circle cx={'10'} cy={'10'} r={'9.17'} stroke={'#BCBFD1'} strokeWidth={'1.66'}/>
    <path d={'M10 9V14'} stroke={'#BCBFD1'} strokeWidth={'1.66'} strokeLinecap={'square'} strokeLinejoin={'bevel'}/>
    <circle cx={'10'} cy={'6'} r={'1'} fill={'#BCBFD1'}/>
  </Svg>
);
