import { createUseApi } from '~/1st-api';
import {
  AppAuthClient,
  AppPaymentClient,
  AppRoleClient,
  AppSessionClient,
  AppUserClient,
} from './clients';

export const useApi = createUseApi(() => ({
  auth: new AppAuthClient(),
  payment: new AppPaymentClient(),
  role: new AppRoleClient(),
  session: new AppSessionClient(),
  user: new AppUserClient(),
}));
