import React from 'react';
import { Footer, Container, Header } from '~/ui';

type LayoutProps = {
  children: JSX.Element;
  isNeedHeader?: boolean;
};
export const Layout: React.FC<LayoutProps> = (props) => (
  <div>
    {props?.isNeedHeader && <Header/>}
    <Container>
      {props.children}
    </Container>
    <Footer/>
  </div>
);
