import { mapOrVoid } from '~/1st-api';

export type SentPayment = {
  itemId?: string;
  name?: string;
  phone?: string;
  address?: string;
  date?: string;
};

export type SentPaymentRaw = {
  item_id?: string;
  name?: string;
  phone?: string;
  address?: string;
  date?: string;
};

export function mapSentPayment(data: SentPayment): SentPaymentRaw {
  return {
    item_id: mapOrVoid(data.itemId, String),
    name: mapOrVoid(data.name, String),
    phone: mapOrVoid(data.phone, String),
    address: mapOrVoid(data.address, String),
    date: mapOrVoid(data.date, String),
  };
}

export function mapSentPaymentRaw(dataRaw: SentPaymentRaw): SentPayment {
  return {
    itemId: mapOrVoid(dataRaw.item_id, String),
    name: mapOrVoid(dataRaw.name, String),
    phone: mapOrVoid(dataRaw.phone, String),
    address: mapOrVoid(dataRaw.address, String),
    date: mapOrVoid(dataRaw.date, String),
  };
}
