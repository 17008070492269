import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { mediaBreakpoints } from '../common';
import logo from '../images/logo.png';
import { Container } from './container';
import { GoBackButton } from './go-back-button';

const Wrapper = styled.div<{ showBackground?: boolean; }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80em;
  background: ${(props) => (props.showBackground ? 'rgba(255, 255, 255, 0.86)' : '')};
  backdrop-filter: blur(${(props) => (props.showBackground ? '4px' : '0px')});
  display: flex;
  z-index: 100;
  align-items: center;
  font-size: 1px;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    height: 50em;
    background: ${(props) => (props.showBackground ? 'rgba(255, 255, 255, 0.86)' : '')};
  }

  .header-logo {
    height: 36em;
    @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
      height: 20em;
    }
  }
`;

const ContainerLocal = styled(Container)<{ alignToLeft?: boolean; }>`
  max-width: 1000em;
  ${(props) => {
    if (props.alignToLeft) {
      return css`
        margin-left: 50px;
        @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
          margin-left: 10px;
        }
      `;
    }
    return '';
  }};
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    max-width: calc(100% - 32em);
  }
`;

type HeaderProps = {
  showGoToHomeButton?: boolean;
  alignToLeft?: boolean;
};

export const Header: React.FC<HeaderProps> = (props) => {
  const [searchParams] = useSearchParams();

  const handleLogoClick = (e: SyntheticEvent) => {
    e.preventDefault();
    window.scroll(0, 0);
  };

  const [showBackground, setShowBackground] = useState(false);

  useEffect(() => {
    const handler = () => {
      if (window.scrollY > 50 && showBackground === false) {
        setShowBackground(true);
      }
      if (window.scrollY <= 50 && showBackground === true) {
        setShowBackground(false);
      }
    };

    document.addEventListener('scroll', handler);
    return () => {
      document.removeEventListener('scroll', handler);
    };
  }, [showBackground]);

  const prisholFromMainLanding = searchParams.get('openFrom') === 'mainLanding';

  return (
    <Wrapper className={'header'} showBackground={showBackground}>
      <ContainerLocal alignToLeft={props.alignToLeft}>
        {
          props.showGoToHomeButton || prisholFromMainLanding
            ? <GoBackButton link={prisholFromMainLanding ? 'https://mytech.global' : undefined}/>
            : (
              <a href={'https://mytech.global'} onClick={handleLogoClick}>
                <img src={logo} alt={'МайТех'} className={'header-logo'}/>
              </a>
            )
        }
      </ContainerLocal>
    </Wrapper>
  );
};
