import {
  Stack,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useState, useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { Header, Footer } from '~/ui';
import { CoffeeMachineImage } from './coffee-machine-image';
import { Description } from './description';
import { DialogTitleInfo } from './dialog-title-info';
import { DialogTitleOrder } from './dialog-title-order';
import { DialogTitleWrapper } from './dialog-title-wrapper';
import { FreezerFrigeImage } from './freezer-fridge-image';
import { InfoModalMobile } from './info-modal-mobile';
import { LaundryMachineImage } from './laundry-machine-image';
import { Modal } from './modal';
import { OrderModal } from './order-modal';
import { OrderModalMobile } from './order-modal-mobile';
import { UnitCard } from './unit-card';
import { WashingMachineImage } from './washing-machine-image';
import { Welcome } from './welcome';

function getWindowSize() {
  const {
    innerWidth,
    innerHeight,
  } = window;
  return {
    innerWidth,
    innerHeight,
  };
}

const Transition = React.forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Slide direction={'up'} ref={ref} {...props}/>);

const washingMachineFeatures = [
  'Проверка давления воды',
  'Проверка воды на примеси (предложение фильтра)',
  'Балансировка',
  'Пробный запуск и прогон на высокой температуре со средством от накипи (наше средство)',
  'Проверка нагреваемой воды',
  'При необходимости механическая очистка тена от накипи',
  'Химическая очистка стиральной машины от накипи и отложений со средством содержащим сульфаминовую кислоту',
  'Консультация по прибору: правильные моющие средства правила стирки прогон раз в месяц на 90 градусов режимы количество загружаемого белья и т.д.',
];

const coffeeMachineFeatures = [
  'Декальцинация кофемашины со специальным средством. Удаление пробок кальция. (средство для очистки входит в услугу)',
  'Очистка гидросистемы со специальным средством.Декофенация. Дезинфекция. (средство для очистки входит в услугу)',
  'Физическая очистка варочного блока',
  'Обработка варочного блока специальным средством (средство для очистки входит в услугу)',
  'Очистка капучинатора (средство для очистки входит в услугу)',
  'Замена капучинатора (без учета стоимости капучинатора)',
  'Замена варочного блока (без учёта стоимости варочного блока)',
  'Замена фильтра для воды кофемашины (без учёта стоимости фильтра)',
  'Профессиональная консультация специалиста по эксплуатации изделия',
  'Регулировка настроек',
  'Тестирование кофемашины на работоспособность',
];

const freedgeFeatures = [
  'Очистка змеевика конденсатора от пыли',
  'Визуальный осмотр, для выявления механических дефектов',
  'Осмотр уплотнительных резинок дверей',
  'Регулировка дверей при необходимости',
  'Очистка дренажного отверстия (без разбора)',
  'Замер температур в камерах изделия',
  'Проверка и очистка (при необходимости) воздушных каналов',
  'Выравнивание изделия по уровню (при необходимости).',
  'Профессиональная консультация специалиста по эксплуатации изделия',
  'Проверка и затяжка опор компрессора (если разболтались)',
  'Консультация по износу прибора и возможным заменам',
  'Очистка радиатора и вентилятора',
  'Визуальный осмотр соединений и магистралей, на предмет утечки',
];

const laundryMachineFeatures = [
  'Очистка машины от накипи со специальным средством (средство для очистки входит в услугу)',
  'Визуальный осмотр прибора',
  'Проверка напора воды в системе',
  'Проверка корректности подключения к магистралям',
  'Выставление изделия по уровню (если есть необходимость)',
  'Проверка сливной системы',
  'Проверка и очистка (при необходимости) воздушных каналов',
  'Выравнивание изделия по уровню (при необходимости).',
  'Чистка машины со специальным средство от белковых отложений, если требуется физическое удаление отложений и остатков пищи, без разбора изделия (средство для очистки входит в услугу)',
  'Проверка импеллеров (разбрызгивателей) на целостность, (очистка при необходимости)',
  'Проверка корректности работы программ изделия',
  'Визуальная проверка шлангов на герметичность',
  'Профессиональная консультация специалиста по эксплуатации изделия',
  'Проверка жесткости воды',
];

const useStyles = createUseStyles({
  Main__UnitCardBlock: {
    'border': '1px solid red',
    'alignSelf': 'center',
    'alignItems': 'center',
    'justifyContent': 'space-between',
    'width': 1000,
    'height': 600,
    'display': 'flex',
    'flexDirection': 'row',
    'flexWrap': 'wrap',
    '@media(max-width: 1032px)': {
      alignItems: 'center',
      width: '90%',
      height: 950,
      justifyContent: 'space-between',
    },
    '@media(max-width: 340px)': {
      alignItems: 'center',
      width: '100%',
      height: 700,
      justifyContent: 'space-between',

    },
  },
  Main__UnitCardUnit: {
    // display: 'flex',
    // flexDirection: 'row',
  },
  Main__UserCard: {
    '@media(max-width: 1032px)': {
      display: 'flex',
      // alignItems: '',
      flexWrap: 'wrap',
      justifyContent: 'center',
      border: '1px solid black',
    },
  },
});

export const Main: React.FC = (props) => {
  const [openWashingMachineOrderModal, setOpenWashingMachineOrderModal] = useState({
    opened: false,
    back: false,
  });
  const [openCoffeeMachineOrderModal, setOpenCoffeeMachineOrderModal] = useState({
    opened: false,
    back: false,
  });
  const [openLaundryMachineOrderModal, setOpenLaundryMachineOrderModal] = useState({
    opened: false,
    back: false,
  });
  const [openFreedgeOrderModal, setOpenFreedgeOrderModal] = useState({
    opened: false,
    back: false,
  });
  const [openWashingMachine, setOpenWashingMachine] = useState(false);
  // const onOpenWashingMachineClick =
  const descriptionElementWashingMachineRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (openWashingMachine) {
      const { current: descriptionElement } = descriptionElementWashingMachineRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openWashingMachine]);
  // const [openWashingMachineOrder, setOpenWashingMachineOrder] = useState(false);

  const [openCoffeeMachine, setOpenCoffeeMachine] = useState(false);
  const descriptionElementCoffeeMachineRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (openCoffeeMachine) {
      const { current: descriptionElement } = descriptionElementCoffeeMachineRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openCoffeeMachine]);

  const [openLaundryMachine, setOpenLaundryMachine] = useState(false);
  const descriptionElementLaundryMachineRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (openLaundryMachine) {
      const { current: descriptionElement } = descriptionElementLaundryMachineRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openLaundryMachine]);

  const [openFreedge, setOpenFreedge] = useState(false);
  const descriptionElementFreedgeRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (openFreedge) {
      const { current: descriptionElement } = descriptionElementFreedgeRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openFreedge]);
  const styles = useStyles();

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  console.log('windowSize', windowSize);
  return (
    <Stack spacing={12}>
      <Stack>
        <Header/>
        <Welcome small={windowSize.innerWidth < 700}/>
      </Stack>
      <Stack
        // width={windowSize.innerWidth < 600 && '100vw'}
        width={'100%'}
        alignSelf={'center'}
        alignItems={'center'}
        spacing={windowSize.innerWidth < 1054 ? 4 : 6}
        margin={0}
      >
        <Stack
          // flexWrap={'wrap'}
          margin={'auto'}
          alignItems={'center'}
          direction={windowSize.innerWidth < 1054 ? 'column' : 'row'}
          spacing={4}
          className={styles.Main__UnitCardUnit}
        >
          <UnitCard
            icon={<WashingMachineImage/>}
            title={'стиральной машины'}
            price={'5 000 ₽'}
            onMoreClick={() => {
              setOpenWashingMachine(true);
            }}
            onButtonClick={() => {
              setOpenWashingMachine(true);
              setOpenWashingMachineOrderModal({
                opened: true,
                back: false,
              });
            }}
          />
          {windowSize.innerWidth < 470 ? (
              <Dialog
                fullScreen
                open={openWashingMachine}
                onClose={() => setOpenWashingMachine(false)}
                TransitionComponent={Transition}
              >
                {openWashingMachineOrderModal.opened ? (
                    <OrderModalMobile
                      onClose={() => {
                        setOpenWashingMachine(false);
                        setOpenWashingMachineOrderModal({
                          opened: false,
                          back: false,
                        });
                      }}
                      onBack={openWashingMachineOrderModal.back && (() => {
                        setOpenWashingMachineOrderModal({
                          opened: false,
                          back: false,
                        });
                      })}
                      id={'1'}
                      title={'Обслуживание стиральной машины'}
                      price={'5 000 ₽'}
                    />
                  )
                  : (
                    <InfoModalMobile
                      title={'Обслуживание стиральной машины'}
                      price={'5 000 ₽'}
                      onBackClick={() => setOpenWashingMachine(false)}
                      onButtonClick={() => {
                        // setOpenWashingMachine(false);
                        setOpenWashingMachineOrderModal({
                          opened: true,
                          back: true,
                        });
                      }}
                      features={washingMachineFeatures}
                    />
                  )}
              </Dialog>
            )
            : (
              <Dialog
                fullWidth
                open={openWashingMachine}
                onClose={() => {
                  setOpenWashingMachine(false);
                  setOpenWashingMachineOrderModal({
                    opened: false,
                    back: false,
                  });
                }}
                aria-labelledby={'washing-machine-alert-dialog-title'}
                aria-describedby={'washing-machine-alert-dialog-description'}
              >
                {openWashingMachineOrderModal.opened
                  ? (
                    <>
                      <DialogTitleWrapper
                        id={'washing-machine-scroll-dialog-title'}
                        close={() => {
                          setOpenWashingMachine(false);
                          setOpenWashingMachineOrderModal({
                            opened: false,
                            back: false,
                          });
                        }}
                      >
                        <DialogTitleOrder
                          back={openWashingMachineOrderModal.back && (() => {
                            setOpenWashingMachineOrderModal({
                              opened: false,
                              back: false,
                            });
                          })}
                        />
                      </DialogTitleWrapper>
                      <DialogContent>
                        <DialogContentText
                          id={'washing-machine-scroll-dialog-description'}
                          ref={descriptionElementWashingMachineRef}
                          tabIndex={-1}
                        >
                          <OrderModal
                            id={'1'}
                            title={'Обслуживание стиральной машины'}
                            price={'5 000 ₽'}
                          />
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Stack height={2}/>
                      </DialogActions>
                    </>
                  )
                  : (
                    <>
                      <DialogTitleWrapper
                        id={'washing-machine-scroll-dialog-title'}
                        close={() => {
                          setOpenWashingMachine(false);
                          setOpenWashingMachineOrderModal({
                            opened: false,
                            back: false,
                          });
                        }}
                      >
                        <DialogTitleInfo
                          title={'Обслуживание стиральной машины'}
                          price={'5 000 ₽'}
                          onButtonClick={() => {
                            // setOpenWashingMachine(false);
                            setOpenWashingMachineOrderModal({
                              opened: true,
                              back: true,
                            });
                          }}
                          icon={<WashingMachineImage/>}
                        />
                      </DialogTitleWrapper>
                      <DialogContent>
                        <DialogContentText
                          id={'washing-machine-scroll-dialog-description'}
                          ref={descriptionElementWashingMachineRef}
                          tabIndex={-1}
                        >
                          <Modal
                            features={washingMachineFeatures}
                          />
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Stack height={2}/>
                      </DialogActions>
                    </>
                  )}
              </Dialog>
            )}
          <UnitCard
            icon={<CoffeeMachineImage/>}
            title={'кофемашины'}
            price={'5 000 ₽'}
            onMoreClick={() => {
              setOpenCoffeeMachine(true);
            }}
            onButtonClick={() => {
              setOpenCoffeeMachine(true);
              setOpenCoffeeMachineOrderModal({
                opened: true,
                back: false,
              });
            }}
          />
          {windowSize.innerWidth < 470
            ? (
              <Dialog
                fullScreen
                open={openCoffeeMachine}
                onClose={() => setOpenCoffeeMachine(false)}
                TransitionComponent={Transition}
              >
                {openCoffeeMachineOrderModal.opened ? (
                    <OrderModalMobile
                      id={'2'}
                      onClose={() => {
                        setOpenCoffeeMachine(false);
                        setOpenCoffeeMachineOrderModal({
                          opened: false,
                          back: false,
                        });
                      }}
                      onBack={openCoffeeMachineOrderModal.back && (() => {
                        setOpenCoffeeMachineOrderModal({
                          opened: false,
                          back: false,
                        });
                      })}
                      title={'Обслуживание кофемашины'}
                      price={'5 000 ₽'}
                    />
                  )
                  : (
                    <InfoModalMobile
                      title={'Обслуживание кофемашины'}
                      price={'5 000 ₽'}
                      onBackClick={() => setOpenCoffeeMachine(false)}
                      onButtonClick={() => {
                        // setOpenWashingMachine(false);
                        setOpenCoffeeMachineOrderModal({
                          opened: true,
                          back: true,
                        });
                      }}
                      features={coffeeMachineFeatures}
                    />
                  )}
              </Dialog>
            )
            : (
              <Dialog
                fullWidth
                open={openCoffeeMachine}
                onClose={() => {
                  setOpenCoffeeMachine(false);
                  setOpenCoffeeMachineOrderModal({
                    opened: false,
                    back: false,
                  });
                }}
                aria-labelledby={'coffee-machine-alert-dialog-title'}
                aria-describedby={'offee-machine-alert-dialog-description'}
              >
                {openCoffeeMachineOrderModal.opened
                  ? (
                    <>
                      <DialogTitleWrapper
                        id={'coffee-machine-scroll-dialog-title'}
                        close={() => {
                          setOpenCoffeeMachine(false);
                          setOpenCoffeeMachineOrderModal({
                            opened: false,
                            back: false,
                          });
                        }}
                      >
                        <DialogTitleOrder
                          back={openCoffeeMachineOrderModal.back && (() => {
                            setOpenCoffeeMachineOrderModal({
                              opened: false,
                              back: false,
                            });
                          })}
                        />
                      </DialogTitleWrapper>
                      <DialogContent>
                        <DialogContentText
                          id={'coffee-machine-scroll-dialog-description'}
                          ref={descriptionElementCoffeeMachineRef}
                          tabIndex={-1}
                        >
                          <OrderModal
                            id={'2'}
                            title={'Обслуживание кофемашины'}
                            price={'5 000 ₽'}
                          />
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Stack height={2}/>
                      </DialogActions>
                    </>
                  )
                  : (
                    <>
                      <DialogTitleWrapper
                        id={'сoffee-machine-scroll-dialog-title'}
                        close={() => {
                          setOpenCoffeeMachine(false);
                          setOpenCoffeeMachineOrderModal({
                            opened: false,
                            back: false,
                          });
                        }}
                      >
                        <DialogTitleInfo
                          title={'Обслуживание кофемашины'}
                          price={'5 000 ₽'}
                          onButtonClick={() => {
                            setOpenCoffeeMachineOrderModal({
                              opened: true,
                              back: true,
                            });
                          }}
                          icon={<CoffeeMachineImage/>}
                        />
                      </DialogTitleWrapper>
                      <DialogContent>
                        <DialogContentText
                          id={'сoffee-machine-scroll-dialog-description'}
                          ref={descriptionElementCoffeeMachineRef}
                          tabIndex={-1}
                        >
                          <Modal
                            features={coffeeMachineFeatures}
                          />
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Stack height={2}/>
                      </DialogActions>
                    </>
                  )}
              </Dialog>
            )}
        </Stack>
        <Stack
          // margin={'auto'}
          alignItems={'center'}
          direction={windowSize.innerWidth < 1054 ? 'column' : 'row'}
          spacing={4}
          className={styles.Main__UnitCardUnit}
        >
          <UnitCard
            icon={<LaundryMachineImage/>}
            title={'посудомоечной машины'}
            price={'5 000 ₽'}
            onMoreClick={() => {
              setOpenLaundryMachine(true);
            }}
            onButtonClick={() => {
              setOpenLaundryMachine(true);
              setOpenLaundryMachineOrderModal({
                opened: true,
                back: false,
              });
            }}
          />
          {windowSize.innerWidth < 470
            ? (
              <Dialog
                fullScreen
                open={openLaundryMachine}
                onClose={() => setOpenLaundryMachine(false)}
                TransitionComponent={Transition}
              >
                {openLaundryMachineOrderModal.opened ? (
                    <OrderModalMobile
                      id={'3'}
                      onClose={() => {
                        setOpenLaundryMachine(false);
                        setOpenLaundryMachineOrderModal({
                          opened: false,
                          back: false,
                        });
                      }}
                      onBack={openLaundryMachineOrderModal.back && (() => {
                        setOpenLaundryMachineOrderModal({
                          opened: false,
                          back: false,
                        });
                      })}
                      title={'Обслуживание посудомоечной машины'}
                      price={'5 000 ₽'}
                    />
                  )
                  : (
                    <InfoModalMobile
                      title={'Обслуживание посудомоечной машины'}
                      price={'5 000 ₽'}
                      onBackClick={() => setOpenLaundryMachine(false)}
                      onButtonClick={() => {
                        // setOpenWashingMachine(false);
                        setOpenLaundryMachineOrderModal({
                          opened: true,
                          back: true,
                        });
                      }}
                      features={laundryMachineFeatures}
                    />
                  )}
              </Dialog>
            )
            : (
              <Dialog
                fullWidth
                open={openLaundryMachine}
                onClose={() => {
                  setOpenLaundryMachine(false);
                  setOpenLaundryMachineOrderModal({
                    opened: false,
                    back: false,
                  });
                }}
                aria-labelledby={'Laundry-machine-alert-dialog-title'}
                aria-describedby={'Laundry-machine-alert-dialog-description'}
              >
                {openLaundryMachineOrderModal.opened
                  ? (
                    <>
                      <DialogTitleWrapper
                        id={'Laundry-machine-scroll-dialog-title'}
                        close={() => {
                          setOpenLaundryMachine(false);
                          setOpenLaundryMachineOrderModal({
                            opened: false,
                            back: false,
                          });
                        }}
                      >
                        <DialogTitleOrder
                          back={openLaundryMachineOrderModal.back && (() => {
                            setOpenLaundryMachineOrderModal({
                              opened: false,
                              back: false,
                            });
                          })}
                        />
                      </DialogTitleWrapper>
                      <DialogContent>
                        <DialogContentText
                          id={'Laundry-machine-scroll-dialog-description'}
                          ref={descriptionElementLaundryMachineRef}
                          tabIndex={-1}
                        >
                          <OrderModal
                            id={'3'}
                            title={'Обслуживание посудомоечной машины'}
                            price={'5 000 ₽'}
                          />
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Stack height={2}/>
                      </DialogActions>
                    </>
                  )
                  : (
                    <>
                      <DialogTitleWrapper
                        id={'Laundry-machine-scroll-dialog-title'}
                        close={() => {
                          setOpenLaundryMachine(false);
                          setOpenLaundryMachineOrderModal({
                            opened: false,
                            back: false,
                          });
                        }}
                      >
                        <DialogTitleInfo
                          title={'Обслуживание посудомоечной машины'}
                          price={'5 000 ₽'}
                          onButtonClick={() => {
                            setOpenLaundryMachineOrderModal({
                              opened: true,
                              back: true,
                            });
                          }}
                          icon={<LaundryMachineImage/>}
                        />
                      </DialogTitleWrapper>
                      <DialogContent>
                        <DialogContentText
                          id={'wLaundry-machine-scroll-dialog-description'}
                          ref={descriptionElementLaundryMachineRef}
                          tabIndex={-1}
                        >
                          <Modal
                            features={laundryMachineFeatures}
                          />
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Stack height={2}/>
                      </DialogActions>
                    </>
                  )}
              </Dialog>
            )}
          <UnitCard
            icon={<FreezerFrigeImage/>}
            title={'холодильника/морозильника'}
            price={'5 000 ₽'}
            onMoreClick={() => {
              setOpenFreedge(true);
            }}
            onButtonClick={() => {
              setOpenFreedge(true);
              setOpenFreedgeOrderModal({
                opened: true,
                back: false,
              });
            }}
          />
          {windowSize.innerWidth < 470 ? (
              <Dialog
                fullScreen
                open={openFreedge}
                onClose={() => setOpenFreedge(false)}
                TransitionComponent={Transition}
              >
                {openFreedgeOrderModal.opened ? (
                    <OrderModalMobile
                      onClose={() => {
                        setOpenFreedge(false);
                        setOpenFreedgeOrderModal({
                          opened: false,
                          back: false,
                        });
                      }}
                      onBack={openFreedgeOrderModal.back && (() => {
                        setOpenFreedgeOrderModal({
                          opened: false,
                          back: false,
                        });
                      })}
                      id={'4'}
                      title={'Обслуживание холодильника/морозильника'}
                      price={'5 000 ₽'}
                    />
                  )
                  : (
                    <InfoModalMobile
                      title={'Обслуживание холодильника/морозильника'}
                      price={'5 000 ₽'}
                      onBackClick={() => setOpenFreedge(false)}
                      onButtonClick={() => {
                        // setOpenWashingMachine(false);
                        setOpenFreedgeOrderModal({
                          opened: true,
                          back: true,
                        });
                      }}
                      features={freedgeFeatures}
                    />
                  )}
              </Dialog>
            )
            : (
              <Dialog
                fullWidth
                open={openFreedge}
                onClose={() => {
                  setOpenFreedge(false);
                  setOpenFreedgeOrderModal({
                    opened: false,
                    back: false,
                  });
                }}
                aria-labelledby={'Freedge-machine-alert-dialog-title'}
                aria-describedby={'Freedge-machine-alert-dialog-description'}
              >
                {openFreedgeOrderModal.opened
                  ? (
                    <>
                      <DialogTitleWrapper
                        id={'Freedge-machine-scroll-dialog-title'}
                        close={() => {
                          setOpenFreedge(false);
                          setOpenFreedgeOrderModal({
                            opened: false,
                            back: false,
                          });
                        }}
                      >
                        <DialogTitleOrder
                          back={openFreedgeOrderModal.back && (() => {
                            setOpenFreedgeOrderModal({
                              opened: false,
                              back: false,
                            });
                          })}
                        />
                      </DialogTitleWrapper>
                      <DialogContent>
                        <DialogContentText
                          id={'Freedge-machine-scroll-dialog-description'}
                          ref={descriptionElementFreedgeRef}
                          tabIndex={-1}
                        >
                          <OrderModal
                            id={'4'}
                            title={'Обслуживание холодильника/морозильника'}
                            price={'5 000 ₽'}
                          />
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Stack height={2}/>
                      </DialogActions>
                    </>
                  )
                  : (
                    <>
                      <DialogTitleWrapper
                        id={'Freedge-machine-scroll-dialog-title'}
                        close={() => {
                          setOpenFreedge(false);
                          setOpenFreedgeOrderModal({
                            opened: false,
                            back: false,
                          });
                        }}
                      >
                        <DialogTitleInfo
                          title={'Обслуживание холодильника/морозильника'}
                          price={'5 000 ₽'}
                          onButtonClick={() => {
                            // setOpenWashingMachine(false);
                            setOpenFreedgeOrderModal({
                              opened: true,
                              back: true,
                            });
                          }}
                          icon={<FreezerFrigeImage/>}
                        />
                      </DialogTitleWrapper>
                      <DialogContent>
                        <DialogContentText
                          id={'Freedge-machine-scroll-dialog-description'}
                          ref={descriptionElementFreedgeRef}
                          tabIndex={-1}
                        >
                          <Modal
                            features={freedgeFeatures}
                          />
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Stack height={2}/>
                      </DialogActions>
                    </>
                  )}
              </Dialog>
            )}
        </Stack>
      </Stack>
      <Description/>
      <Footer/>
    </Stack>
  );
};
// // flexWrap={'wrap'}
// // margin={'auto'}
// // alignItems={'center'}
// direction={windowSize.innerWidth < 930 ? 'column' : 'row'}
// spacing={4}
// sx={{ border: '1px solid green' }}
// className={styles.Main__UnitCardUnit}

//
// <Stack
//   flexWrap={'wrap'}
//   margin={'auto'}
//   alignItems={'center'}
//   className={styles.Main__UnitCardBlock}
// >
//   <UnitCard
//     icon={<WashingMachineImage/>}
//     title={'стиральной машины'}
//     price={'5 000 ₽'}
//     onMoreClick={() => {
//       setOpenWashingMachine(true);
//     }}
//     onButtonClick={() => {
//       setOpenWashingMachine(true);
//       setOpenWashingMachineOrderModal({
//         opened: true,
//         back: false,
//       });
//     }}
//   />
//   {windowSize.innerWidth < 470 ? (
//       <Dialog
//         fullScreen
//         open={openWashingMachine}
//         onClose={() => setOpenWashingMachine(false)}
//         TransitionComponent={Transition}
//       >
//         {openWashingMachineOrderModal.opened ? (
//             <OrderModalMobile
//               onClose={() => {
//                 setOpenWashingMachine(false);
//                 setOpenWashingMachineOrderModal({
//                   opened: false,
//                   back: false,
//                 });
//               }}
//               onBack={openWashingMachineOrderModal.back && (() => {
//                 setOpenWashingMachineOrderModal({
//                   opened: false,
//                   back: false,
//                 });
//               })}
//               title={'Обслуживание стиральной машины'}
//               price={'5 000 ₽'}
//             />
//           )
//           : (
//             <InfoModalMobile
//               title={'Обслуживание стиральной машины'}
//               price={'5 000 ₽'}
//               onBackClick={() => setOpenWashingMachine(false)}
//               onButtonClick={() => {
//                 // setOpenWashingMachine(false);
//                 setOpenWashingMachineOrderModal({
//                   opened: true,
//                   back: true,
//                 });
//               }}
//               features={washingMachineFeatures}
//             />
//           )}
//       </Dialog>
//     )
//     : (
//       <Dialog
//         fullWidth
//         open={openWashingMachine}
//         onClose={() => {
//           setOpenWashingMachine(false);
//           setOpenWashingMachineOrderModal({
//             opened: false,
//             back: false,
//           });
//         }}
//         aria-labelledby={'washing-machine-alert-dialog-title'}
//         aria-describedby={'washing-machine-alert-dialog-description'}
//       >
//         {openWashingMachineOrderModal.opened
//           ? (
//             <>
//               <DialogTitleWrapper
//                 id={'washing-machine-scroll-dialog-title'}
//                 close={() => {
//                   setOpenWashingMachine(false);
//                   setOpenWashingMachineOrderModal({
//                     opened: false,
//                     back: false,
//                   });
//                 }}
//               >
//                 <DialogTitleOrder
//                   back={openWashingMachineOrderModal.back && (() => {
//                     setOpenWashingMachineOrderModal({
//                       opened: false,
//                       back: false,
//                     });
//                   })}
//                 />
//               </DialogTitleWrapper>
//               <DialogContent>
//                 <DialogContentText
//                   id={'washing-machine-scroll-dialog-description'}
//                   ref={descriptionElementWashingMachineRef}
//                   tabIndex={-1}
//                 >
//                   <OrderModal
//                     title={'Обслуживание стиральной машины'}
//                     price={'5 000 ₽'}
//                   />
//                 </DialogContentText>
//               </DialogContent>
//               <DialogActions>
//                 <Stack height={2}/>
//               </DialogActions>
//             </>
//           )
//           : (
//             <>
//               <DialogTitleWrapper
//                 id={'washing-machine-scroll-dialog-title'}
//                 close={() => {
//                   setOpenWashingMachine(false);
//                   setOpenWashingMachineOrderModal({
//                     opened: false,
//                     back: false,
//                   });
//                 }}
//               >
//                 <DialogTitleInfo
//                   title={'Обслуживание стиральной машины'}
//                   price={'5 000 ₽'}
//                   onButtonClick={() => {
//                     // setOpenWashingMachine(false);
//                     setOpenWashingMachineOrderModal({
//                       opened: true,
//                       back: true,
//                     });
//                   }}
//                   icon={<WashingMachineImage/>}
//                 />
//               </DialogTitleWrapper>
//               <DialogContent>
//                 <DialogContentText
//                   id={'washing-machine-scroll-dialog-description'}
//                   ref={descriptionElementWashingMachineRef}
//                   tabIndex={-1}
//                 >
//                   <Modal
//                     features={washingMachineFeatures}
//                   />
//                 </DialogContentText>
//               </DialogContent>
//               <DialogActions>
//                 <Stack height={2}/>
//               </DialogActions>
//             </>
//           )}
//       </Dialog>
//     )}
//   <UnitCard
//     icon={<CoffeeMachineImage/>}
//     title={'кофемашины'}
//     price={'5 000 ₽'}
//     onMoreClick={() => {
//       setOpenCoffeeMachine(true);
//     }}
//     onButtonClick={() => {
//       setOpenCoffeeMachine(true);
//       setOpenCoffeeMachineOrderModal({
//         opened: true,
//         back: false,
//       });
//     }}
//   />
//   {windowSize.innerWidth < 470
//     ? (
//       <Dialog
//         fullScreen
//         open={openCoffeeMachine}
//         onClose={() => setOpenCoffeeMachine(false)}
//         TransitionComponent={Transition}
//       >
//         {openCoffeeMachineOrderModal.opened ? (
//             <OrderModalMobile
//               onClose={() => {
//                 setOpenCoffeeMachine(false);
//                 setOpenCoffeeMachineOrderModal({
//                   opened: false,
//                   back: false,
//                 });
//               }}
//               onBack={openCoffeeMachineOrderModal.back && (() => {
//                 setOpenCoffeeMachineOrderModal({
//                   opened: false,
//                   back: false,
//                 });
//               })}
//               title={'Обслуживание кофемашины'}
//               price={'5 000 ₽'}
//             />
//           )
//           : (
//             <InfoModalMobile
//               title={'Обслуживание кофемашины'}
//               price={'5 000 ₽'}
//               onBackClick={() => setOpenCoffeeMachine(false)}
//               onButtonClick={() => {
//                 // setOpenWashingMachine(false);
//                 setOpenCoffeeMachineOrderModal({
//                   opened: true,
//                   back: true,
//                 });
//               }}
//               features={coffeeMachineFeatures}
//             />
//           )}
//       </Dialog>
//     )
//     : (
//       <Dialog
//         fullWidth
//         open={openCoffeeMachine}
//         onClose={() => {
//           setOpenCoffeeMachine(false);
//           setOpenCoffeeMachineOrderModal({
//             opened: false,
//             back: false,
//           });
//         }}
//         aria-labelledby={'coffee-machine-alert-dialog-title'}
//         aria-describedby={'offee-machine-alert-dialog-description'}
//       >
//         {openCoffeeMachineOrderModal.opened
//           ? (
//             <>
//               <DialogTitleWrapper
//                 id={'coffee-machine-scroll-dialog-title'}
//                 close={() => {
//                   setOpenCoffeeMachine(false);
//                   setOpenCoffeeMachineOrderModal({
//                     opened: false,
//                     back: false,
//                   });
//                 }}
//               >
//                 <DialogTitleOrder
//                   back={openCoffeeMachineOrderModal.back && (() => {
//                     setOpenCoffeeMachineOrderModal({
//                       opened: false,
//                       back: false,
//                     });
//                   })}
//                 />
//               </DialogTitleWrapper>
//               <DialogContent>
//                 <DialogContentText
//                   id={'coffee-machine-scroll-dialog-description'}
//                   ref={descriptionElementCoffeeMachineRef}
//                   tabIndex={-1}
//                 >
//                   <OrderModal
//                     title={'Обслуживание кофемашины'}
//                     price={'5 000 ₽'}
//                   />
//                 </DialogContentText>
//               </DialogContent>
//               <DialogActions>
//                 <Stack height={2}/>
//               </DialogActions>
//             </>
//           )
//           : (
//             <>
//               <DialogTitleWrapper
//                 id={'сoffee-machine-scroll-dialog-title'}
//                 close={() => {
//                   setOpenCoffeeMachine(false);
//                   setOpenCoffeeMachineOrderModal({
//                     opened: false,
//                     back: false,
//                   });
//                 }}
//               >
//                 <DialogTitleInfo
//                   title={'Обслуживание кофемашины'}
//                   price={'5 000 ₽'}
//                   onButtonClick={() => {
//                     setOpenCoffeeMachineOrderModal({
//                       opened: true,
//                       back: true,
//                     });
//                   }}
//                   icon={<CoffeeMachineImage/>}
//                 />
//               </DialogTitleWrapper>
//               <DialogContent>
//                 <DialogContentText
//                   id={'сoffee-machine-scroll-dialog-description'}
//                   ref={descriptionElementCoffeeMachineRef}
//                   tabIndex={-1}
//                 >
//                   <Modal
//                     features={coffeeMachineFeatures}
//                   />
//                 </DialogContentText>
//               </DialogContent>
//               <DialogActions>
//                 <Stack height={2}/>
//               </DialogActions>
//             </>
//           )}
//       </Dialog>
//     )}
//   <UnitCard
//     icon={<LaundryMachineImage/>}
//     title={'посудомоечной машины'}
//     price={'5 000 ₽'}
//     onMoreClick={() => {
//       setOpenLaundryMachine(true);
//     }}
//     onButtonClick={() => {
//       setOpenLaundryMachine(true);
//       setOpenLaundryMachineOrderModal({
//         opened: true,
//         back: false,
//       });
//     }}
//   />
//   {windowSize.innerWidth < 470
//     ? (
//       <Dialog
//         fullScreen
//         open={openLaundryMachine}
//         onClose={() => setOpenLaundryMachine(false)}
//         TransitionComponent={Transition}
//       >
//         {openCoffeeMachineOrderModal.opened ? (
//             <OrderModalMobile
//               onClose={() => {
//                 setOpenLaundryMachine(false);
//                 setOpenLaundryMachineOrderModal({
//                   opened: false,
//                   back: false,
//                 });
//               }}
//               onBack={openLaundryMachineOrderModal.back && (() => {
//                 setOpenLaundryMachineOrderModal({
//                   opened: false,
//                   back: false,
//                 });
//               })}
//               title={'Обслуживание посудомоечной машины'}
//               price={'5 000 ₽'}
//             />
//           )
//           : (
//             <InfoModalMobile
//               title={'Обслуживание посудомоечной машины'}
//               price={'5 000 ₽'}
//               onBackClick={() => setOpenLaundryMachine(false)}
//               onButtonClick={() => {
//                 // setOpenWashingMachine(false);
//                 setOpenLaundryMachineOrderModal({
//                   opened: true,
//                   back: true,
//                 });
//               }}
//               features={laundryMachineFeatures}
//             />
//           )}
//       </Dialog>
//     )
//     : (
//       <Dialog
//         fullWidth
//         open={openLaundryMachine}
//         onClose={() => {
//           setOpenLaundryMachine(false);
//           setOpenLaundryMachineOrderModal({
//             opened: false,
//             back: false,
//           });
//         }}
//         aria-labelledby={'Laundry-machine-alert-dialog-title'}
//         aria-describedby={'Laundry-machine-alert-dialog-description'}
//       >
//         {openLaundryMachineOrderModal.opened
//           ? (
//             <>
//               <DialogTitleWrapper
//                 id={'Laundry-machine-scroll-dialog-title'}
//                 close={() => {
//                   setOpenLaundryMachine(false);
//                   setOpenLaundryMachineOrderModal({
//                     opened: false,
//                     back: false,
//                   });
//                 }}
//               >
//                 <DialogTitleOrder
//                   back={openLaundryMachineOrderModal.back && (() => {
//                     setOpenLaundryMachineOrderModal({
//                       opened: false,
//                       back: false,
//                     });
//                   })}
//                 />
//               </DialogTitleWrapper>
//               <DialogContent>
//                 <DialogContentText
//                   id={'Laundry-machine-scroll-dialog-description'}
//                   ref={descriptionElementLaundryMachineRef}
//                   tabIndex={-1}
//                 >
//                   <OrderModal
//                     title={'Обслуживание посудомоечной машины'}
//                     price={'5 000 ₽'}
//                   />
//                 </DialogContentText>
//               </DialogContent>
//               <DialogActions>
//                 <Stack height={2}/>
//               </DialogActions>
//             </>
//           )
//           : (
//             <>
//               <DialogTitleWrapper
//                 id={'Laundry-machine-scroll-dialog-title'}
//                 close={() => {
//                   setOpenLaundryMachine(false);
//                   setOpenLaundryMachineOrderModal({
//                     opened: false,
//                     back: false,
//                   });
//                 }}
//               >
//                 <DialogTitleInfo
//                   title={'Обслуживание посудомоечной машины'}
//                   price={'5 000 ₽'}
//                   onButtonClick={() => {
//                     setOpenLaundryMachineOrderModal({
//                       opened: true,
//                       back: true,
//                     });
//                   }}
//                   icon={<LaundryMachineImage/>}
//                 />
//               </DialogTitleWrapper>
//               <DialogContent>
//                 <DialogContentText
//                   id={'wLaundry-machine-scroll-dialog-description'}
//                   ref={descriptionElementLaundryMachineRef}
//                   tabIndex={-1}
//                 >
//                   <Modal
//                     features={laundryMachineFeatures}
//                   />
//                 </DialogContentText>
//               </DialogContent>
//               <DialogActions>
//                 <Stack height={2}/>
//               </DialogActions>
//             </>
//           )}
//       </Dialog>
//     )}
//   <UnitCard
//     icon={<FreezerFrigeImage/>}
//     title={'холодильника/морозильника'}
//     price={'5 000 ₽'}
//     onMoreClick={() => {
//       setOpenFreedge(true);
//     }}
//     onButtonClick={() => {
//       setOpenFreedge(true);
//       setOpenFreedgeOrderModal({
//         opened: true,
//         back: false,
//       });
//     }}
//   />
//   {windowSize.innerWidth < 470 ? (
//       <Dialog
//         fullScreen
//         open={openFreedge}
//         onClose={() => setOpenFreedge(false)}
//         TransitionComponent={Transition}
//       >
//         {openFreedgeOrderModal.opened ? (
//             <OrderModalMobile
//               onClose={() => {
//                 setOpenFreedge(false);
//                 setOpenFreedgeOrderModal({
//                   opened: false,
//                   back: false,
//                 });
//               }}
//               onBack={openFreedgeOrderModal.back && (() => {
//                 setOpenFreedgeOrderModal({
//                   opened: false,
//                   back: false,
//                 });
//               })}
//               title={'Обслуживание холодильника/морозильника'}
//               price={'5 000 ₽'}
//             />
//           )
//           : (
//             <InfoModalMobile
//               title={'Обслуживание холодильника/морозильника'}
//               price={'5 000 ₽'}
//               onBackClick={() => setOpenFreedge(false)}
//               onButtonClick={() => {
//                 // setOpenWashingMachine(false);
//                 setOpenFreedgeOrderModal({
//                   opened: true,
//                   back: true,
//                 });
//               }}
//               features={freedgeFeatures}
//             />
//           )}
//       </Dialog>
//     )
//     : (
//       <Dialog
//         fullWidth
//         open={openFreedge}
//         onClose={() => {
//           setOpenFreedge(false);
//           setOpenFreedgeOrderModal({
//             opened: false,
//             back: false,
//           });
//         }}
//         aria-labelledby={'Freedge-machine-alert-dialog-title'}
//         aria-describedby={'Freedge-machine-alert-dialog-description'}
//       >
//         {openFreedgeOrderModal.opened
//           ? (
//             <>
//               <DialogTitleWrapper
//                 id={'Freedge-machine-scroll-dialog-title'}
//                 close={() => {
//                   setOpenFreedge(false);
//                   setOpenFreedgeOrderModal({
//                     opened: false,
//                     back: false,
//                   });
//                 }}
//               >
//                 <DialogTitleOrder
//                   back={openFreedgeOrderModal.back && (() => {
//                     setOpenFreedgeOrderModal({
//                       opened: false,
//                       back: false,
//                     });
//                   })}
//                 />
//               </DialogTitleWrapper>
//               <DialogContent>
//                 <DialogContentText
//                   id={'Freedge-machine-scroll-dialog-description'}
//                   ref={descriptionElementFreedgeRef}
//                   tabIndex={-1}
//                 >
//                   <OrderModal
//                     title={'Обслуживание холодильника/морозильника'}
//                     price={'5 000 ₽'}
//                   />
//                 </DialogContentText>
//               </DialogContent>
//               <DialogActions>
//                 <Stack height={2}/>
//               </DialogActions>
//             </>
//           )
//           : (
//             <>
//               <DialogTitleWrapper
//                 id={'Freedge-machine-scroll-dialog-title'}
//                 close={() => {
//                   setOpenFreedge(false);
//                   setOpenFreedgeOrderModal({
//                     opened: false,
//                     back: false,
//                   });
//                 }}
//               >
//                 <DialogTitleInfo
//                   title={'Обслуживание холодильника/морозильника'}
//                   price={'5 000 ₽'}
//                   onButtonClick={() => {
//                     // setOpenWashingMachine(false);
//                     setOpenFreedgeOrderModal({
//                       opened: true,
//                       back: true,
//                     });
//                   }}
//                   icon={<FreezerFrigeImage/>}
//                 />
//               </DialogTitleWrapper>
//               <DialogContent>
//                 <DialogContentText
//                   id={'Freedge-machine-scroll-dialog-description'}
//                   ref={descriptionElementFreedgeRef}
//                   tabIndex={-1}
//                 >
//                   <Modal
//                     features={freedgeFeatures}
//                   />
//                 </DialogContentText>
//               </DialogContent>
//               <DialogActions>
//                 <Stack height={2}/>
//               </DialogActions>
//             </>
//           )}
//       </Dialog>
//     )}
// </Stack>
