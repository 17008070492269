import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(
  {
    viewBox: '0 0 100 100', fill: 'none', xmlns: 'http://www.w3.org/2000/svg',
  },
)`
  width: 100px;
  height: 100px;
  @media (max-width: 768px) {
    width: 65px;
    height: 65px;
  }
`;

export const ProcessingErrorIcon = () => (
  <Svg>
    <ellipse cx={'49.4205'} cy={'50.0001'} rx={'47.6744'} ry={'47.6744'} fill={'#F37773'} fillOpacity={'0.16'}/>
    <rect x={'9.30078'} y={'9.30225'} width={'81.3954'} height={'81.3954'} rx={'40.6977'} fill={'#FFD4D2'}/>
    <path fillRule={'evenodd'} clipRule={'evenodd'} d={'M50 32C59.9418 32 68 40.0601 68 50C68 59.9399 59.9418 68 50 68C40.0601 68 32 59.9399 32 50C32 40.0601 40.0601 32 50 32Z'} stroke={'#F55241'} strokeWidth={'4'} strokeLinecap={'round'} strokeLinejoin={'round'}/>
    <path d={'M49.9886 42.6133V51.2124'} stroke={'#F55241'} strokeWidth={'4'} strokeLinecap={'round'} strokeLinejoin={'round'}/>
    <path d={'M49.9906 57.3865H50.01'} stroke={'#F55241'} strokeWidth={'4'} strokeLinecap={'round'} strokeLinejoin={'round'}/>
  </Svg>
);
