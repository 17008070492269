import {
  Stack,
  Typography,
  TextField,
  Checkbox,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useOrderService } from '~/main/di';
import {
  Header,
  Color,
  IconCheckboxNotChecked,
  IconCheckboxChecked,
} from '~/ui';
import { Footer } from './footer';
import { Submit } from './submit';
import { TextFieldWrapper } from './text-field-wrapper';
import { UnitCardFinal } from './unit-card-final';

const useStyles = createUseStyles({
  Finish__Block: {
    'width': 1040,
    'justifyContent': 'space-between',
    'paddingTop': '5vh',
    'alignSelf': 'center',
    'height': 800,
    '@media(max-width: 927px)': {
      height: 'auto',
      border: '1px solid green',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  Finish__LeftPart: {
    'maxWidth': '450px',
    '@media(max-width: 1032px)': {
      alignSelf: 'center',
      width: '100%',
    },
  },
  Finish__RightPart: {
    'width': 450,
    'minHeight': '244px',
    'backgroundColor': Color.White,
    'borderRadius': 20,
    'padding': '24px',
    '@media(max-width: 1032px)': {
      border: '1px solid red',
      width: 'auto',
      marginTop: 20,
      padding: '12px',
      minHeight: '100px',
    },
  },
});

export const Finish = () => {
  const styles = useStyles();

  const navigate = useNavigate();

  const orderService = useOrderService();

  const formik = useFormik<{
    email: string;
    cardNumber: string;
    date: string;
    cvv: string;
    oferta: boolean;
  }>({
    initialValues: {
      email: '',
      cardNumber: '',
      date: '',
      cvv: '',
      oferta: true,
    },
    validationSchema: Yup.object()
      .shape({
        email: Yup.string()
          .email('Невалидный email')
          .required('Незаполненное поле!'),
        cardNumber: Yup.string()
          .required('Незаполненное поле!'),
        date: Yup.string()
          .required('Незаполненное поле!'),
        cvv: Yup.string()
          .required('Незаполненное поле!'),
      }),
    onSubmit: async (data) => {
      // try {
      //   await mainService.sendForVendorsAndDistributors(data);
      //   setSendResult(null);
      // } catch (err) {
      //   setSendResult(err.message);
      // }
    },
  });

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  // const { price, title } = orderService.orderData$.value;
  useEffect(() => {
    if (!orderService.orderData$?.value) navigate('/');
  }, []);

  console.log('ORDER DATA', orderService.orderData$.value);

  if (!orderService.orderData$.value) {
    return (
      <span>404</span>
    );
  }
  return (
    <Stack spacing={16}>
      <Header/>
      <Stack
        alignSelf={'center'}
        flexWrap={'wrap'}
        margin={'auto'}
        alignItems={'center'}
        flexDirection={'row'}
        className={styles.Finish__Block}
      >
        <Stack
          className={styles.Finish__LeftPart}
          spacing={3.4}
        >
          <Stack spacing={2}>
            <Typography
              fontWeight={'bold'}
              fontSize={'32px'}
            >
              Ваш заказ
            </Typography>
            <Typography
              color={Color.Gray2}
              fontSize={'22px'}
            >
              После оплаты, наш менеджер свяжется с вами в течение 3-х часов
            </Typography>
          </Stack>
          <UnitCardFinal
            title={orderService.orderData$.value?.title}
            price={orderService.orderData$.value?.price}
            onMoreClick={() => {
            }}
          />
          <TextFieldWrapper>
            <TextField
              placeholder={'Введите телефон или e-mail для чека'}
              required
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              {...formik.getFieldProps('email')}
              error={Boolean(formik.touched.email && formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}

            />
          </TextFieldWrapper>
        </Stack>
        <Stack
          alignSelf={'center'}
          spacing={2.8}
          className={styles.Finish__RightPart}
        >
          <Typography
            fontWeight={'bold'}
            fontSize={'32px'}
          >
            Оплата картой
          </Typography>
          <TextFieldWrapper>
            <TextField
              placeholder={'Номер карты'}
              required
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              {...formik.getFieldProps('cardNumber')}
              error={Boolean(formik.touched.cardNumber && formik.errors.cardNumber)}
              helperText={formik.touched.cardNumber && formik.errors.cardNumber}

            />
          </TextFieldWrapper>
          <Stack
            direction={'row'}
            spacing={2}
          >
            <TextFieldWrapper>
              <TextField
                placeholder={'мм/гг'}
                required
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...formik.getFieldProps('date')}
                error={Boolean(formik.touched.date && formik.errors.date)}
                helperText={formik.touched.date && formik.errors.date}

              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              <TextField
                placeholder={'cvv'}
                required
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...formik.getFieldProps('cvv')}
                error={Boolean(formik.touched.cvv && formik.errors.cvv)}
                helperText={formik.touched.cvv && formik.errors.cvv}

              />
            </TextFieldWrapper>
          </Stack>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Checkbox
              icon={<IconCheckboxNotChecked/>}
              checkedIcon={<IconCheckboxChecked/>}
              defaultChecked
              required
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              {...formik.getFieldProps('oferta')}
            />
            <p>
                      <span>
                        Ознакомился с &nbsp;
                      </span>
              <a
                href={'/consent-processing-personal-data'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                Публичной офертой
              </a>
            </p>
          </Stack>
          <Submit onClick={handleSubmit}>Оплатить</Submit>
        </Stack>
      </Stack>
      <Footer/>
    </Stack>
  );
};
