import React from 'react';

type BackIconProps = {
  backgroundColor?: string;
};

export const BackIcon = (props: BackIconProps) => (
  <svg
    width={'40'}
    height={'40'}
    viewBox={'0 0 40 40'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <rect
      width={'40'}
      height={'40'}
      rx={'20'}
      fill={props.backgroundColor || '#F6F6FB'}
    />
    <path
      d={'M22 15L17 20L22 25'}
      stroke={'#1B2040'}
      strokeWidth={'2'}
      strokeLinejoin={'bevel'}
    />
  </svg>
);
