import { Stack, Typography, Divider } from '@mui/material';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Color, Footer } from '~/ui';
import { BackIcon } from './back-icon';
import { DoneImage } from './done-image';

const useStyles = createUseStyles({
  InfoModalMobile: {
    backgroundColor: Color.Gray1,
    position: 'relative',
  },
  InfoModalMobile__BackIcon: {
    position: 'absolute',
    top: 20,
    left: 8,
    zIndex: 1,
  },
  InfoModalMobile__DialogTitleInfo: {
    'backgroundColor': Color.Gray1,
    'paddingTop': '50px',
    'position': 'fixed',
    'top': 0,
    'right': 0,
    'left': 0,
    'fontSize': '23px',
    '@media(max-width: 470px)': {
      fontSize: '15px',
    },
  },
  InfoModalMobile__TitleBlock: {
    'padding': '0 30px 0 35px',
    '@media(max-width: 470px)': {
      padding: '0px',
      alignSelf: 'center',
    },
  },
  InfoModalMobile__ButtonBlock: {
    padding: '0 30px 0 35px',
  },
  InfoModalMobile__ModalButton: {
    'backgroundColor': '#1B2040',
    'color': Color.White,
    'padding': '12px 67px 12px 67px',
    'borderRadius': 12,
    'border': 'none',
    'cursor': 'pointer',
    '@media(max-width: 339px)': {
      padding: '6px 30px 6px 30px',
    },
  },
  InfoModalMobile__WashingMachineModal: {
    'backgroundColor': Color.Gray1,
    'height': '50vh',
    'overflow': 'scroll',
    // 'position': 'relative',
    // 'padding': '10px 45px 15px 54px',
    '@media(max-width: 1032px)': {
      padding: '3px 10px 5px 5px',
    },
  },
  InfoModalMobile__Close: {
    position: 'absolute',
    top: 36,
    right: 36,
    cursor: 'pointer',
  },
  InfoModalMobile__Button: {
    'backgroundColor': '#1B2040',
    'color': Color.White,
    'padding': '12px 67px 12px 67px',
    'borderRadius': 12,
    'border': 'none',
    'cursor': 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(9, 14, 43, 1)',
    },
    '&:active': {
      backgroundColor: 'rgba(6, 10, 33, 1)',
    },
  },
});

type InfoModalMobileProps = {
  title: string;
  price: string;
  onButtonClick: () => void;
  onBackClick: () => void;
  features: Array<string>;
};

export const InfoModalMobile = (props: InfoModalMobileProps) => {
  const styles = useStyles();
  return (
    <Stack sx={{ backgroundColor: Color.Gray1 }} spacing={3.5}>
      <Stack className={styles.InfoModalMobile} spacing={28}>
        <Stack
          className={styles.InfoModalMobile__DialogTitleInfo}
          spacing={2.4}
        >
          <Stack
            onClick={props.onBackClick}
            className={styles.InfoModalMobile__BackIcon}
          >
            <BackIcon backgroundColor={Color.White}/>
          </Stack>
          <Stack
            direction={'row'}
            spacing={2.5}
            width={'80%'}
            className={styles.InfoModalMobile__TitleBlock}
            alignItems={'center'}
          >
            <Typography
              fontWeight={'normal'}
              fontSize={22}
            >
              {props.title}
            </Typography>
          </Stack>
          <Stack
            direction={'row'}
            flexWrap={'wrap'}
            alignItems={'center'}
            width={'80%'}
            justifyContent={'space-between'}
            className={styles.InfoModalMobile__ButtonBlock}
          >
            <Typography
              color={'#7E2FED'}
              fontWeight={'bold'}
              fontSize={'1.4em'}
            >
              {props.price}
            </Typography>
            <button
              className={styles.InfoModalMobile__ModalButton}
              onClick={props.onButtonClick}
            >
              <Typography fontSize={'1em'}>Заказать</Typography>
            </button>
          </Stack>
          <Divider/>
        </Stack>
        <div
          className={styles.InfoModalMobile__WashingMachineModal}
        >
          <Stack spacing={3.5}>
            <Typography
              component={'div'}
              fontSize={14}
              fontWeight={400}
              color={Color.Gray3}
            >
              ЧТО ВХОДИТ
            </Typography>
            {props.features.map((feature, index) => (
              <Stack
                key={index}
                spacing={3.5}
              >
                <Stack
                  direction={'row'}
                  spacing={1.2}
                  alignItems={'start'}
                >
                  <div style={{ width: 30 }}>
                    <DoneImage/>
                  </div>
                  <Typography
                    component={'div'}
                    fontSize={18}
                    fontWeight={400}
                    color={'rgba(27, 32, 64, 1)'}
                  >
                    {feature}
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </div>
      </Stack>
      <Footer/>
    </Stack>
  );
};
