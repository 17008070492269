import { Stack, Typography } from '@mui/material';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Color } from '~/ui/common';

type NumberProps = {
  number: number;
};

const useStyles = createUseStyles({
  Number: {
    // width: 50,
    // height: 50,
    borderRadius: 40,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Color.White,
    pointerEvents: 'none',
  },
  Number__Integer: {
    // color: 'rgba(97, 86, 213, 1)',
    // fontSize: 28,
    // fontWeight: 600,
    // lineHeight: '140%',
  },
});

export const Number = (props: NumberProps) => {
  const styles = useStyles();
  return (
    <Stack
      sx={{
        width: ['38px', '41px', '44px', '47px', '50x'],
        height: ['38px', '41px', '44px', '47px', '50px'],
      }}
      className={styles.Number}
    >
      <Typography
        fontWeight={600}
        color={'rgba(97, 86, 213, 1)'}
        lineHeight={'140%'}
        sx={{
          fontSize: ['18px', '20px', '22px', '25px', '28px'],
        }}
      >
        {props.number}
      </Typography>
    </Stack>
  );
};
