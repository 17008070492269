import styled from 'styled-components';

export const Submit = styled('div')`
  font-weight: 500;
  font-size: 18px;
  border-radius: 16px;
  line-height: 125%;
  color: #F5F7FC;
  width: 100%;
  height: 60px;
  background: #1B2040;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
