import styled from 'styled-components';
import { mediaBreakpoints } from '../common';

export const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  max-width: 1000px;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    max-width: calc(100% - 32px);
  }
`;
