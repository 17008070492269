import React from 'react';
import { Stack, Typography } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { Number } from './number';

const useStyles = createUseStyles({
  Description: {
    'paddingTop': 100,
    '@media(max-width: 1032px)': {
      padding: '0 10px 0 10px',
    },
  },
});

export const Description = () => {
  const styles = useStyles();
  return (
    <Stack
      className={styles.Description}
      alignItems={'center'}
      spacing={6}
    >
      <Typography
        sx={{
          fontSize: ['28px', '34px', '38px', '42px', '46px'],
        }}
        fontWeight={'bold'}
      >
        Как это работает?
      </Typography>
      <Stack spacing={4}>
        <Stack
          alignItems={'center'}
          spacing={2}
          direction={'row'}
        >
          <div>
            <Number number={1}/>
          </div>
          <Typography
            sx={{
              fontSize: ['16px', '18px', '20px', '21px', '22px'],
            }}
            fontWeight={400}
          >
            Оформляете заявку на нашем сайте
          </Typography>
        </Stack>
        <Stack
          alignItems={'center'}
          spacing={2}
          direction={'row'}
        >
          <div>
            <Number number={2}/>
          </div>
          <Typography
            sx={{
              fontSize: ['16px', '18px', '20px', '21px', '22px'],
            }}
            fontWeight={400}
          >
            Оплачиваете услугу
          </Typography>
        </Stack>
        <Stack
          alignItems={'center'}
          spacing={2}
          direction={'row'}
        >
          <div>
            <Number number={3}/>
          </div>
          <Typography
            sx={{
              fontSize: ['16px', '18px', '20px', '21px', '22px'],
            }}
            fontWeight={400}
          >
            Наш менеджер связывается с нами в течение 3-х часов для уточнения деталей
          </Typography>
        </Stack>
        <Stack
          alignItems={'center'}
          spacing={2}
          direction={'row'}
        >
          <div>
            <Number number={4}/>
          </div>
          <Typography
            sx={{
              fontSize: ['16px', '18px', '20px', '21px', '22px'],
            }}
            fontWeight={400}
          >
            Мастер приезжает к вам в назначенное время и проводит обслуживание техники
          </Typography>
        </Stack>
      </Stack>
      <Typography
        textAlign={'center'}
        sx={{
          fontSize: ['18px', '20px', '22px', '25px', '28px'],
        }}
      >
        С нами ваша техника всегда работает!
      </Typography>
    </Stack>
  );
};
